import BaseController from '../basePlayerController';
import eGameStateTypes from '../../../../statistic/eGameStateTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerGameState extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_LABEL_WRAPPER: 'label_wrapper',
    };

    this.init(container);
  }


  init(container) {
    super.init(container);
    window.OPUtility.ControllerStatistic.onUpdate.add(this.onStatisticUpdate.bind(this));
    this.setGameState(OPUtility.ControllerStatistic.gameState);
  }

  onStatisticUpdate({currentGameState}) {
    this.setGameState(currentGameState)
  }

  setGameState(state) {
    this._currentGameStateType = state || eGameStateTypes.EGST_REGULAR;
    const localizedState = this._getLocalization(`${OP_WRAPPER_LOCALIZATION_PREFIX}.infoPanel.gameStates.${this._currentGameStateType}`);
    this.set(this._eElementsTypes.EET_LABEL, localizedState);
    if (this._currentGameStateType === eGameStateTypes.EGST_REGULAR) {
      this.interactiveElements[this._eElementsTypes.EET_LABEL_WRAPPER].classList.remove('bonus_state')
    } else {
      this.interactiveElements[this._eElementsTypes.EET_LABEL_WRAPPER].classList.add('bonus_state')
    }
  }

  _getMarkup() {
    return `<span  class="game_mode" id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_WRAPPER]}>
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}></span>
          </span>`
  }
}
