import BaseSystemController from '../baseSystemController';

const RESULTS_SERVICE_ORIGIN = 'https://round-results.onlyplay.net';
// const RESULTS_SERVICE_ORIGIN = 'http://0.0.0.0:3000';
const historyServiceProvidePostMessageEvents = {
  READY: 'Ready',
  ROUND_DATA_RECEIVED: 'Data received',
  STYLES_RECEIVED: 'Styles received',
}
const historyServiceAcceptPostMessageEvents = {
  UPDATE_STYLES: 'Update styles',
  ROUND_PARAMS: 'Round params'
}

export default class ControllerRoundsResult extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_BACK: 'back_button',
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_BACK]: () => this.close(),
    };
    this._eEventTypes = {
      BACK_CLICK: this.getEventName(this._eButtonsTypes.EBT_BACK),
    };
    this._eElementsTypes = {
      EET_ROOT: 'root',
      EET_BACK_BUTTON: this._eButtonsTypes.EBT_BACK,
      EET_CONTAINER_CONTENT: 'content',
    };

    this._currentResultId = null;
    this._removeMessageListener = null;

    this.init(container);
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_ROOT];
  }

  cleanup() {
    super.cleanup();
    this._removeMessageListener && this._removeMessageListener();
    this._reset();
    this.close();
  }

  async open(round) {
    try {
      await new Promise((resolve, reject) => {
        this._rejectOpening = reject;
        const { region, provider_round: id } = round;
        const data = this._data[region] ? this._data[region][id] : undefined;

        // if (!data) {
        //   this._reset();
        //   return;
        // } //todo: maybe show some error screen

        if (id !== this._currentResultId) {
          this._removeMessageListener && this._removeMessageListener();
          this.set(this._eElementsTypes.EET_CONTAINER_CONTENT, '');
          this._currentResultId = id;

          const params = {
            round_params: {
              round_info: round,
              results_info: data,
            },
            version: data?.version,
          }

          const json = JSON.stringify({ eventName: historyServiceAcceptPostMessageEvents.ROUND_PARAMS, params });

          this._iframe = document.createElement('iframe');

          this._iframe.src = `${RESULTS_SERVICE_ORIGIN}/round-info?theme=dark`;

          const onMessage = (event) => {
            console.log(event.origin);
            if (event.origin !== RESULTS_SERVICE_ORIGIN) return;

            switch (event.data) {
              case historyServiceProvidePostMessageEvents.READY: {
                this._updateIframeStyles();
                event.source.postMessage(json, RESULTS_SERVICE_ORIGIN);
                return;
              }
              case historyServiceProvidePostMessageEvents.ROUND_DATA_RECEIVED: {
                resolve();
                return;
              }
              case historyServiceProvidePostMessageEvents.STYLES_RECEIVED: return;
              default: console.log(`Unhandled event ${event.data}`);
            }
          }

          window.addEventListener('message', onMessage);
          this._removeMessageListener = () => window.removeEventListener('message', onMessage);
          this.interactiveElements[this._eElementsTypes.EET_CONTAINER_CONTENT].append(this._iframe);
        } else {
          resolve();
        }
      });

      this.root.classList.add('open');
    } catch (e) {
    }
  }

  close() {
    this.root.classList.remove('open');
  }

  _reset() {
    this._currentResultId = null;
    this._removeMessageListener = null;
    this.set(this._eElementsTypes.EET_CONTAINER_CONTENT, '');
    this._iframe = null;
    this._rejectOpening && this._rejectOpening();
  }

  _updateIframeStyles() {
    if (this._iframe) {
      const mobileFontSizeMultiplier = 1.3;
      const fontSize = this._scaleData.orientation === 'V' ? this._scaleData.currentFontSize * mobileFontSizeMultiplier : this._scaleData.currentFontSize;
      this._iframe.contentWindow.postMessage(JSON.stringify({ eventName: historyServiceAcceptPostMessageEvents.UPDATE_STYLES, params: { fontSize } }), RESULTS_SERVICE_ORIGIN);
    }
  }

  get dataSelector() {
    return (data) => data.results;
  }

  set scaleData({ scaleData }) {
    this._scaleData = scaleData;
    this._updateIframeStyles();
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_ROOT]} class="system-ui-container__modal__rounds_history__body__result">
              <div class="system-ui-container__modal__rounds_history__body__result__head">
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BACK_BUTTON]}>
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 51.3333H35C46.6667 51.3333 51.3333 46.6667 51.3333 35V21C51.3333 9.33334 46.6667 4.66667 35 4.66667H21C9.33334 4.66667 4.66667 9.33334 4.66667 21V35C4.66667 46.6667 9.33334 51.3333 21 51.3333Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30.94 36.2367L22.7267 28L30.94 19.7633" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_CONTENT]} class="system-ui-container__modal__rounds_history__body__result__content">
              </div>
            </div>
            <div class="system-ui-container__modal__rounds_history__body__result_blocker"></div>`
  }
}
