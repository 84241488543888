import BaseController from '../basePlayerController';
import { format } from '../../../../utils/formatCurrency';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerNetPosition extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.infoPanel.netPosition`,
    };

    this._netPosition = 0;

    this.init(container);
  }

  init(container) {
    super.init(container);
    window.OPUtility.ControllerStatistic.onUpdate.add(this.onStatisticUpdate.bind(this));
    this._update();
  }

  onStatisticUpdate({ totalWinAmount, totalBetAmount }) {
    this._netPosition = totalWinAmount - totalBetAmount;
    this._update();
  }

  _update() {
    const decimals = window.OPWrapperService.currencyInfo.decimals;
    const currency = window.OPWrapperService.currencyInfo.currency;

    const formattedNetPosition = format(this._netPosition, decimals, false);
    const text = `${formattedNetPosition} ${currency.toUpperCase()}`;
    this.set(this._eElementsTypes.EET_VALUE, text);
  }
  _getMarkup() {
    return `<span>
              <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}:
              </label>
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
                0.12345678
              </span>
            </span>`
  }
}
