import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerFreespinsCount extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._eLabelsTypes = {
      ELT_FREESPINS_LEFT: 'freespins_left',
      ELT_FREESPINS_COMPLETED: 'freespins_completed',
    };
    this._dynamicLocalizations = {
      [this._eLabelsTypes.ELT_FREESPINS_LEFT]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.freespins_left_label`,
      [this._eLabelsTypes.ELT_FREESPINS_COMPLETED]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.freespins_completed_label`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this.init(container);
    this.hide();
  }

  setLabel(type) {
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: this._dynamicLocalizations[type],
    };
    this._updateLocalizations();

    switch (type) {
      case this._eLabelsTypes.ELT_FREESPINS_LEFT: return this.interactiveElements[this._eElementsTypes.EET_VALUE].classList.remove('hidden');
      case this._eLabelsTypes.ELT_FREESPINS_COMPLETED: return this.interactiveElements[this._eElementsTypes.EET_VALUE].classList.add('hidden');
      default: throw new Error(`Unhandled freespins label type: '${type}'`);
    }
  }

  setValue(value) {
    this.set(this._eElementsTypes.EET_VALUE, value);
  }

  get labelTypes() {
    return this._eLabelsTypes;
  }

  _getMarkup() {
    return `<div class="ui-container__title-desc-col ui-container__freespins_count">
          <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
          </label>
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
            99
          </span>
        </div>`
  }
}
