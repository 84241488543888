import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default class InitState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('game:gameStarted', this.onGameStarted, this);
    }

    onGameStarted() {
        this.complete();
    }
}