import { sound } from '@pixi/sound';
import SoundGroup from './SoundGroup';

/**
 * Класс упарвления звуками в игре
 */
export default new class SoundManager {
  constructor() {
    this.sound = null;
    this._soundsConfig = {};
    this._groups = {};

    this._blurMutedState = {};

    this._masterVolume = 1;

    this.addListeners();

    this._getSound();
    this.addNewSoundGroup('default');
  }

  addListeners() {
    window.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
  }

  _onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.unmuteAll();
    } else {
      this.muteAll();
    }
  }

  getSoundConfigData(alias) {
    return this.soundsConfig[alias] || {};
  }

  unmuteAll() {
    Object.values(this.groups).forEach(group => {
      group.forceMuted = false;
    });
  }

  muteAll() {
    Object.values(this.groups).forEach(group => {
      group.forceMuted = true;
    });
  }

  /**
   * Получить текущий звуковой файл
   * @private
   */
  _getSound() {
    this.sound = sound;
    this.sound.disableAutoPause = true;
  }

  /**
   * Воспроизвести звук по имени
   * @param alias имя звука
   * @param volume
   * @param loop
   * @param groupName
   */
  play(alias, volume = 1, loop = false, groupName = 'default', start, end) {
    let sound = this.getSound(alias);
    if (sound) {
      let config = this.getSoundConfigData(alias);
      let vol = this._masterVolume * config.hasOwnProperty('volume') ? config.volume : volume;
      let loo = config.hasOwnProperty('loop') ? config.loop : loop;
      let gro = config.hasOwnProperty('group') ? config.group : groupName;

      const playSettings = { loop: loo, volume: vol };
      if (typeof start === 'number') playSettings.start = start;
      if (typeof end === 'number') playSettings.end = end;
      let instance = sound.play(playSettings);
      sound.baseVolume = volume;
      this.addSoundToGroup(sound, alias, gro);
      return instance;
    } else {
      console.warn(`Don't have sound: ${alias}`)
    }
  }

  /**
   * Остановить звук по имени
   * @param alias имя звука
   */
  stop(alias) {
    let sound = this.getSound(alias);
    if (sound) {
      let instance = sound.stop();
      return instance;
    }
  }

  /**
   *
   * @param alias
   */
  pause(alias) {
    let sound = this.getSound(alias);
    if (sound.isPlaying) {
      sound.pause(alias);
    }
  }

  /**
   *
   * @param alias
   */
  resume(alias) {
    let sound = this.getSound(alias);
    if (sound && !sound.isPlaying) {
      sound.resume(alias);
    }
  }

  /**
   *
   * @param groupName
   * @param value
   */
  muteGroup(groupName, value) {
    let group = this.getGroup(groupName);
    if (group) {
      if (value === undefined) {
        group.switchMuted();
      } else {
        group.muted = value;
      }
    }
  }

  /**
   *
   * @param groupName
   * @param value
   */
  volumeGroup(groupName, value) {
    let group = this.getGroup(groupName);
    if (group) {
      group.volume = value;
    }
  }

  /**
   *
   * @param alias
   * @returns {*}
   */
  getSound(alias) {
    return PIXI.Assets.get(alias);
  }

  /**
   *
   * @param groupName
   */
  addNewSoundGroup(groupName) {
    if (!this.groups.hasOwnProperty(groupName)) {
      this._groups[groupName] = new SoundGroup(groupName);
    }
  }

  /**
   *
   * @param sound
   * @param alias
   * @param groupName
   */
  addSoundToGroup(sound, alias, groupName) {
    let group = this.getGroup(groupName);
    group.addSound(sound, alias);
  }

  getGroup(groupName) {
    if (!this.groups.hasOwnProperty(groupName)) {
      this.addNewSoundGroup(groupName);
    }
    return this.groups[groupName];
  }

  /**
   *
   * @returns {*}
   */
  get groups() {
    return this._groups;
  }

  set soundsConfig(value) {
    this._soundsConfig = value;
  }

  get soundsConfig() {
    return this._soundsConfig;
  }

  set masterVolume(value) {
    this._masterVolume = value;
  }

  get masterVolume() {
    return this._masterVolume;
  }
}
