import BaseInterface from '../baseInterface';
import { notificationTypes } from '../../../utils/notificationTypes';
import Queue from '../../../utils/queue';

export default class SystemUIInterface extends BaseInterface {
  constructor(...props) {
    super(...props);
    this._popupScreensQueue = new Queue(this._executeScreen.bind(this));
    this._popupScreensActive = false;
    this._captchaListeners = new Set();
  }

  handleModelChange({ partnerConfig, tournamentsInfo, gameInited, preloadingComplete, rounds_history }) {
    this._Controller.partnerConfig = partnerConfig;
    if (!this._controllersInited) {
      this._controllersInited = true;
      this.controllers[this.controllerTypes.ECT_SIDEBAR].init();
    }

    if (gameInited && tournamentsInfo) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].updateData(tournamentsInfo);
    }

    if (preloadingComplete) {
      this.controllers[this.controllerTypes.ECT_BUG_REPORT_PRELOADER_BUTTON].hide();

      if (!this._popupScreensActive) {
        this._popupScreensActive = true;
        this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN]);
        this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN]);
        this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END]);
      }
    }

    this.controllers[this.controllerTypes.ECT_ROUNDS_HISTORY].updateData(rounds_history);
    this.controllers[this.controllerTypes.ECT_WIN_LIMITS].updateData(partnerConfig);
  }

  showNotification(type, data) {
    this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].showNotification(type, data);
  }

  hideNotification(type, data) {
    this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].hideNotification(type, data);
  }

  handleTournamentStart(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].handleStart(tournamentId);
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].handleStart(tournamentId);
    this.controllers[this.controllerTypes.ECT_SIDEBAR].tryInitTournamentsButton();
  }

  handleTournamentEnd(tournamentId) {
    this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].handleEnd(tournamentId);
  }

  handleTournamentAnnouncement(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].handleUpcoming(tournamentId);
    this.controllers[this.controllerTypes.ECT_SIDEBAR].tryInitTournamentsButton();
  }

  showTournamentResults(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS].openArchiveTournament(tournamentId);
    this.toggleControllerVisibility(this.controllerTypes.ECT_TOURNAMENTS, true);
  }

  showTournaments() {
    const futureTournament = window.OPWrapperService.model.data.tournamentsInfo && window.OPWrapperService.model.data.tournamentsInfo.future.find(details => !!details.showAnnouncement);
    if (futureTournament) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].showUpcomingTournament(futureTournament.uniqueId);
    } else {
      this.toggleControllerVisibility(this.controllerTypes.ECT_TOURNAMENTS, true);
    }
  }

  tryToShowLimitsOnStart(config) {
    this.controllers[this.controllerTypes.ECT_WIN_LIMITS].tryToShowLimitsOnStart(config);
  }

  toggleBackgroundSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleBackgroundSound(enabled);
  }

  toggleGameSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleGameSound(enabled);
  }

  toggleWinSoundSettings(enabled) {
    this.controllers[this.controllerTypes.ECT_GAME_SETTINGS].toggleWinSound(enabled);
  }

  showGoogleCaptcha(type) {
    this._captchaListeners.add(type);
    document.body.classList.add('captcha-visible');
    return {
      remove: () => this.hideGoogleCaptcha(type),
      execute: (func, action = 'submit') => {
        window.grecaptcha?.ready(() => window.grecaptcha.execute(this._Controller.googleCaptchaToken, { action }).then(func));
      }
    };
  }

  hideGoogleCaptcha(type) {
    this._captchaListeners.delete(type);
    if (!this._captchaListeners.size) {
      document.body.classList.remove('captcha-visible');
    }
  }

  toggleControllerVisibility(type, visibility) {
    const controller = this.controllers[type];
    if (!controller) return console.error(`Unhandled controller type: '${type}'`);

    const condition = visibility !== undefined ? visibility : controller.isHidden;

    if (condition) {
      controller.show();
    } else {
      controller.hide();
    }
  }

  _executeScreen(controller) {
    return new Promise(async resolve => {
      controller.canBeShown = true;
      await controller.execution;
      resolve();
    })
  }

  get eNotificationTypes () {
    return this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].eNotificationTypes;
  }

  get systemNotificationTypes () {
    return notificationTypes;
  }
}
