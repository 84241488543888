import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerTotalWin extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.total_win`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this.init(container);
    this.hide();
  }

  setValue(value) {
    const numValue = Number(value);
    this.set(this._eElementsTypes.EET_VALUE, numValue ? this._moneyFormat(numValue) : '');
  }

  show(value) {
    super.show();
    if (value) this.setValue(value);
  }

  hide() {
    super.hide();
    this.setValue();
  }

  _getMarkup() {
    return `<div class="ui-container__title-desc-col">
              <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
              </label>
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
                1.12345678
              </span>
            </div>`
  }
}
