import BaseController from './baseSystemController';
import { OP_WRAPPER_GAME_LIMITS_STORAGE_KEY, OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import ScaleManager from '../../../scaleManager/ScaleManager';
import { formatCurrency } from '../../../utils/formatCurrency';
import { isEqual } from '../../../utils/isEqual';

export default class ControllerWinLimits extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._defaultSizes = {
      H: {
        width: 2340,
        height: 1080,
      },
      V: {
        width: 1080,
        height: 2340
      }
    };

    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eElementsTypes = {
      EET_OVERLAY: 'overlay',
      EET_MODAL_CONTAINER: 'modal_container',
      EET_TITLE: 'title',
      EET_MIN_BET_LABEL: 'min_bet_label',
      EET_MIN_BET_VALUE: 'min_bet_value',
      EET_MAX_BET_LABEL: 'max_bet_label',
      EET_MAX_BET_VALUE: 'max_bet_value',
      EET_MAX_WIN_LABEL: 'max_win_label',
      EET_MAX_WIN_VALUE: 'max_win_value',
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.title`,
      [this._eElementsTypes.EET_MIN_BET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.minBet`,
      [this._eElementsTypes.EET_MAX_BET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.maxBet`,
      [this._eElementsTypes.EET_MAX_WIN_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.maxWin`,
    };

    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: this.hide.bind(this),
    };

    this._isSpinBlocker = true;

    this.init(container);
    this.hide();
  }

  tryToShowLimitsOnStart(config) {
    if (!config.showLimitsOnStart || !config.available_bets || !config.maxWin) return;
    if (!localStorage) return this.show();

    const limits = {
      minBet: config.available_bets[0],
      maxBet: config.available_bets[config.available_bets.length - 1],
      maxWin: config.maxWin,
    };

    try {
      const lastGameLimits = localStorage.getItem(OP_WRAPPER_GAME_LIMITS_STORAGE_KEY);
      let isSameLimits;

      if (lastGameLimits) {
        const parsedLimits = JSON.parse(lastGameLimits);
        isSameLimits = isEqual(limits, parsedLimits);
      }

      if (!isSameLimits || !lastGameLimits) {
        localStorage.setItem(OP_WRAPPER_GAME_LIMITS_STORAGE_KEY, JSON.stringify(limits));
        this.show();
      }
    } catch (e) {
      console.error(e);
    }
  }

  _afterInit() {
    super._afterInit();
    this._overlay = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    this._modalContaier = this.interactiveElements[this._eElementsTypes.EET_MODAL_CONTAINER];
    this._overlay.onclick = (e) => {
      if (e.target === this._overlay) this.hide();
    };
  }

  _onDataUpdated() {
    super._onDataUpdated();
    const hasWinLimit = this._data.maxWin !== undefined && this._data.maxWin !== null;
    this.set(this._eElementsTypes.EET_MIN_BET_VALUE, formatCurrency(this._data.available_bets[0], this._data.currency));
    this.set(this._eElementsTypes.EET_MAX_BET_VALUE, formatCurrency(this._data.available_bets[this._data.available_bets.length - 1], this._data.currency));
    this.set(this._eElementsTypes.EET_MAX_WIN_VALUE, hasWinLimit ? formatCurrency(this._data.maxWin, this._data.currency) : '-');
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    if (!this._mounted) return;

    const orientation = this._scaleData.orientation;
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    this._modalContaier.dataset.orientation = scaleData.isPortrait ? 'portrait' : 'landscape';
    this._modalContaier.dataset.deviceType = scaleData.isMobile ? 'mobile' : 'desktop';

    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this._overlay,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale + 2,
      defaultHeight: this._scaleData.innerHeight / uiScale,
    });
  }

  get dataSelector() {
    return (data) => data;
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OVERLAY]} class="system-ui-container__modal_wrapper system-ui-container__modal_wrapper system-ui-container__modal_wrapper__win_limits">
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_MODAL_CONTAINER]} class="system-ui-container__modal_container system-ui-container__modal__win_limits">
        <div class="system-ui-container__modal__win_limits__header">
          <div class="system-ui-container__modal__win_limits__header__title">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</span>
          </div>
          
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__modal__win_limits__header__close_button">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42 14L14 42M14 14L42 42" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <div class="system-ui-container__modal__win_limits__body">
          <div class="system-ui-container__modal__win_limits__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MIN_BET_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_MIN_BET_LABEL])}</span>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MIN_BET_VALUE]}></span>
          </div>
          <div class="system-ui-container__modal__win_limits__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_BET_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_MAX_BET_LABEL])}</span>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_BET_VALUE]}></span>
          </div>
          <div class="system-ui-container__modal__win_limits__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_WIN_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_MAX_WIN_LABEL])}</span>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_WIN_VALUE]}></span>
          </div>
        </div>
      </div>
      </div>`
  }
}
