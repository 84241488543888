import FlashLib from 'flashlib_onlyplay';
import EntryPoint from '../../libs/game-engine/src/EntryPoint';
import GlobalDispatcher from '../../libs/game-engine/src/events/GlobalDispatcher';
import { gsap } from 'gsap';
import { startScreenFontStyle } from '../../fontStyles';
import eEventTypes from '../../enums/eEventTypes';

export default class ExtraCoefPanel extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init()
    this.initAnimation()
    this.addListeners()
  }
  init() {
    this.coefContainer = this.getChildByName('coefContainer');
    this.coefTextField = this.coefContainer.getChildByName('coefAmount');
    this.coefTextField.style = { ...this.coefTextField.style, ...startScreenFontStyle}
    this.coefTextField.y = this.coefTextField.displayData.y + 1;
    this.coefContainer.alpha = 0;
  }
  update() {
    this.coef = EntryPoint.GameModel.extraCoef;
    this.coefTextField.text = `×${this.coef}`
    this.tween.play(0);
  }
  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_EXTRA_REELS_STOPPED, this.update, this)
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE__START, this.reset, this)
  }
  initAnimation() {
    this.tween = gsap.timeline({ paused: true });
    this.tween
      .set(this.coefContainer,{ alpha: 0})
      .set(this.coefContainer.scale, { x: 1, y: 1})
      .to(this.coefContainer,  { alpha: 1, duration: 0.15}, 0)
      .to(this.coefContainer.scale, { x: 1.3, y: 1.3, duration: 0.2}, 0)
      .to(this.coefContainer.scale, { x: 1, y: 1, duration: 0.2});

    this.tweenOut = gsap.timeline({ paused: true });
    this.tweenOut
      .fromTo(this.coefContainer, { alpha: 1 }, { alpha: 0, duration: 0.2 });
  }
  reset() {
    this.tween.pause();
    this.tweenOut.play(0);
  }
}
