import eSymbolIndex from '../controllers/symbols/eSymbolIndex';

export default {
  EAN_BACKGROUND__LANDSCAPE: 'bg_heat_anim',
  EAN_POPUP_BACKGROUND: 'sample_pop-up',
  EAN_SYMBOL_FIRE: 'fire_back',
  EAN_SYMBOL_FRAME: 'frame',
}

export const eSymbolsAnimationNames = {
  [eSymbolIndex.ESI_WILD]: 'wild',
  [eSymbolIndex.ESI_CHERRY]: 'cherry',
  [eSymbolIndex.ESI_WATERMELON]: 'watermelon',
  [eSymbolIndex.ESI_GRAPE]: 'grape',
  [eSymbolIndex.ESI_LEMON]: 'lemon',
  [eSymbolIndex.ESI_ORANGE]: 'orange',
  [eSymbolIndex.ESI_PLUM]: 'plum',
  [eSymbolIndex.ESI_SCATTER]:'scatter',
}

export const eBigWinAnimationNames = {
  START: 'big_win_start',
  IDLE: 'big_win_idle'
}
export const eMegaWinAnimationNames = {
  START: 'mega_win_start',
  IDLE: 'mega_win_idle'
}
export const eEpicWinAnimationNames = {
  START: 'epic_win_start',
  IDLE: 'epic_win_idle'
}
