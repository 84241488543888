import BaseController from '../basePlayerController';

export default class ControllerDisplayRTP extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_DISPLAY_RTP: 'display_rtp',
    };

    this.init(container);
  }

  init(container) {
    super.init(container);
    this.setValue(OPWrapperService.config.infoPanel.displayRtp);
  }

  setValue(value) {
    this.set(this._eElementsTypes.EET_DISPLAY_RTP, `RTP: ${value}`);
  }

  _getMarkup() {
    return `<span id=${this.interactiveElementsIds[this._eElementsTypes.EET_DISPLAY_RTP]}>
    RTP:
          </span>`
  }
}
