import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { startScreenFontStyle, winFontStyle } from '../../fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import eSoundType from '../../sounds/eSoundType';
import eEventTypes from '../../enums/eEventTypes';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { uiConfig } from '../ui/config';
import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import eSymbolIndex from '../symbols/eSymbolIndex';
import uiWinLineAssets from '../../../assets/exported/UIWinLine/UIWinLineAssets.webp';

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.init();
    this.addListeners();
  }


  onResize(data) {
    if (data.isLandscape) {


      this.background.goToFrame(2)
      this.content.visible = true;
      this.portretContent.visible = false;
      this.startButton.pivot.x = 0;
      this.startButton.x = this.startButton.displayData.x;
      this.startButton.scale.set(this.startButton.displayData.scaleX);
      this.startButton.y = this.startButton.displayData.y;

    } else if (data.isPortrait) {
      this.background.goToFrame(1)
      this.content.visible = false;
      this.portretContent.visible = true;
      this.startButton.pivot.x = this.startButton.displayData.width / 2;
      this.startButton.x = this.startButton.displayData.x + this.startButton.displayData.width / 2;
      this.startButton.scale.set(1.4);
      this.startButton.y = this.startButton.displayData.y + 260;
    }
  }

  init() {
    this.content = this.getChildByName('content');
    this.portretContent = this.getChildByName('portretContent');
    this.background = this.getChildByName('background');
    this.visible = false;
    this.interactive = true;
    this.startButton = this.getChildByName('startButton');
    makeHitArea(this.startButton);
    this.setTextsStyle();
    this.setWinAmounts();
    this.slot = this.content.getChildByName('slot');
    this.slot.playAnimation(this.slot.animationTypes.CIRCLE, 400000, 1500, 0, true);
    if (EntryPoint.mobile) {
      this.portretSlot = this.portretContent.getChildByName('slot');
      this.portretSlot.playAnimation(this.portretSlot.animationTypes.CIRCLE, 400000, 1500, 0, true)
    }

  }

  addListeners() {
    GlobalDispatcher.addOnce(eEventTypes.EET_START_SCREEN__SHOW, this.show.bind(this));
    this.startButton.on('pointertap', this.hide, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  setTextsStyle() {
    const content = EntryPoint.mobile ? this.portretContent : this.content;
    const setFunction = (content) => {
      for (let i = 0; i < 2; i++) {
        const text = content.getChildByName(`start_screen_${i}`)
        if (text) text.style = { ...text.style, ...startScreenFontStyle }
      }

      for (let i = 3; i <= 5; i++) {
        const coef = content.getChildByName(`wildsCoef_${i}`)
        coef.style = { ...coef.style, ...startScreenFontStyle }
      }
    }
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);

  }

  setWinAmounts() {
    const showCurrency = true;
    const content = EntryPoint.mobile ? this.portretContent : this.content;
    const setFunction = (content) => {
      const wildCoefs = EntryPoint.GameModel.paytableInfo[1];
      const maxBet = EntryPoint.GameModel.betPossible[EntryPoint.GameModel.betPossible.length - 1];
      const decimal = EntryPoint.GameModel.currencyInfo.decimals;
      const lines = EntryPoint.configData.lines.count;

      for (let i = 3; i <= 5; i++) {
        const coef = content.getChildByName(`wildsCoef_${i}`)
        coef.text = `- ${MoneyFormat.format(wildCoefs[i - 1] * maxBet / lines, showCurrency)}`;
        coef.fitSize(true, true);
        coef.correctPosition(null, 'center');
      }
    }
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    document.addEventListener('keydown', this.onKeyDown)
    this.startButton.enabled = true;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.startButton.enabled = false;
    this.slot.stop();
    if (EntryPoint.mobile) this.portretSlot.stop();
    GlobalDispatcher.dispatch(eEventTypes.EET_START_SCREEN__HIDE);
    document.removeEventListener('keydown', this.onKeyDown);
    OPWrapperService.freeBetsController.show();

    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 0.7, group: 'default' },
      winLineConfig: {
        atlas: uiWinLineAssets,
        idToOptionsAdapter: {
          [eSymbolIndex.ESI_WILD]: { viewBox: '201 1 42 48' },
          [eSymbolIndex.ESI_WATERMELON]: { viewBox: '104 1 46 48' },
          [eSymbolIndex.ESI_CHERRY]: { viewBox: '299 1 48 46' },
          [eSymbolIndex.ESI_GRAPE]: { viewBox: '1 1 48 48' },
          [eSymbolIndex.ESI_LEMON]: { viewBox: '53 1 47 48' },
          [eSymbolIndex.ESI_ORANGE]: { viewBox: '351 1 48 46' },
          [eSymbolIndex.ESI_PLUM]: { viewBox: '154 1 43 48' },
          [eSymbolIndex.ESI_SCATTER]: { viewBox: '247 1 48 47' },
        },
      }
    });
  }
}
