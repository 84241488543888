import FlashLib from 'flashlib_onlyplay';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class BaseWinContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this._previousWin = '';

    this.init();
  }

  init() {
    this.titleText = this.getChildByName('ui_win_label');
    this.valueText = this.getChildByName('ui_win_value');
  }

  showPreviousWin() {
    this.titleText.text = window.OPWrapperService.localizations.getLocalizedText('ui_win_label_last_win');
    this.valueText.text = this._previousWin;
  }

  showCurrentWin(value) {
    this.titleText.text = window.OPWrapperService.localizations.getLocalizedText('ui_win_label');
    this.valueText.text = this._previousWin = MoneyFormat.format(value, true);
  }

  setValue(value) {
    const numValue = Number(value);

    if (numValue) {
      this.showCurrentWin(numValue);
    } else {
      this.showPreviousWin();
    }

    this.titleText.fitSize();
    this.titleText.correctPosition('center', 'top');

    this.valueText.fitSize();
    this.valueText.correctPosition('center', 'center');
  }
}
