import BaseController from './basePlayerController';

export default class ControllerMenu extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_BURGER: 'menu_button',
    };
    this._eEventTypes =  {
      BURGER_CLICK: this.getEventName(this._eButtonsTypes.EBT_BURGER),
    };
    this._eElementsTypes = {
      EET_BURGER_BUTTON: this._eButtonsTypes.EBT_BURGER,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BURGER_BUTTON]} data-color="primary">
            <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M86.3327 52.5C88.2657 52.5 89.8327 54.067 89.8327 56C89.8327 57.7719 88.516 59.2363 86.8076 59.468L86.3327 59.5H25.666C23.733 59.5 22.166 57.933 22.166 56C22.166 54.2281 23.4827 52.7637 25.1911 52.532L25.666 52.5H86.3327Z" fill="white"/>
              <path d="M86.3327 71.167C88.2657 71.167 89.8327 72.734 89.8327 74.667C89.8327 76.4389 88.516 77.9033 86.8076 78.135L86.3327 78.167H25.666C23.733 78.167 22.166 76.6 22.166 74.667C22.166 72.8951 23.4827 71.4307 25.1911 71.1989L25.666 71.167H86.3327Z" fill="white"/>
              <path d="M86.3327 33.8335C88.2657 33.8335 89.8327 35.4005 89.8327 37.3335C89.8327 39.1054 88.516 40.5698 86.8076 40.8015L86.3327 40.8335H25.666C23.733 40.8335 22.166 39.2665 22.166 37.3335C22.166 35.5616 23.4827 34.0972 25.1911 33.8654L25.666 33.8335H86.3327Z" fill="white"/>
            </svg>
          </button>`
  }
}
