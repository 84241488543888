import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerAutoPlayTable extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._initialButtonTypes = {
      EBT_START: 'button_start',
    };
    this._eButtonsTypes = {
      ...this._initialButtonTypes
    };
    this._initialButtonsActions = {
      [this._eButtonsTypes.EBT_START]: this._onStartClick.bind(this),
    }
    this._eButtonsActions = {
      ...this._initialButtonsActions,
    };
    this._eEventTypes = {
      CHANGE_BET_CLICK: this.getEventName('change_bet'),
      START_AUTO_SPINS_CLICK: this.getEventName(this._eButtonsTypes.EBT_START),
    };
    this._initialElementsTypes = {
      EET_LABEL: 'label',
      EET_OPTIONS_LIST_LABEL: 'options_list_label',
      EET_OPTION_BONUS_GAME_WIN: 'option_bonus_game_win',
      EET_OPTION_WIN_MORE_THEN: 'option_win_more_then',
      EET_OPTION_BALANCE_INCREASE: 'option_balance_increase',
      EET_OPTION_BALANCE_DECREASE: 'option_balance_decrease',
      EET_OPTION_ENABLE_QUICK_PLAY: 'enable_quick_play',
      EET_COUNT_BUTTON_CONTAINER: 'count_button_container',
      EET_START_BUTTON: this._eButtonsTypes.EBT_START,
    };
    this._eElementsTypes = {
      ...this._initialElementsTypes
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_label`,
      [this._eElementsTypes.EET_OPTIONS_LIST_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.stop_autoplay_when`,
      [this._eElementsTypes.EET_OPTION_BONUS_GAME_WIN]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_option_bonus_game_win`,
      [this._eElementsTypes.EET_OPTION_WIN_MORE_THEN]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_option_win_more_then`,
      [this._eElementsTypes.EET_OPTION_BALANCE_INCREASE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_option_balance_increase`,
      [this._eElementsTypes.EET_OPTION_BALANCE_DECREASE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_option_balance_decrease`,
      [this._eElementsTypes.EET_OPTION_ENABLE_QUICK_PLAY]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.autoplay_option_enable_quick_spin`,
      [this._eElementsTypes.EET_START_BUTTON]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.autoplayTable.start`,
    };

    this._isOverlayToggler = true;

    this.init(container);
    this._updateAvailableCounts();
    this.hide();
  }

  _updateAvailableCounts() {
    this._availableCounts = OPWrapperService.autoPlay.availableCounts;
    this._activeIndex = OPWrapperService.autoPlay.activeCountIndex;
    this._setCountButtons();
    this._updateCountButtons();
  }

  init(container) {
    super.init(container);
    OPWrapperService.autoPlay.onChange.add(this._updateSettings.bind(this));
    this._updateSettings();
  }

  _updateSettings() {
    if (this._availableCounts !== OPWrapperService.autoPlay.availableCounts) {
      this._updateAvailableCounts();
    } else if (this._activeIndex !== OPWrapperService.autoPlay.activeCountIndex) {
      this._activeIndex = OPWrapperService.autoPlay.activeCountIndex;
      this._updateCountButtons();
    }

    this._interactiveElements[this._eElementsTypes.EET_OPTION_BONUS_GAME_WIN].querySelector('input').checked = OPWrapperService.autoPlay.stopByBonusGameWin
    this._interactiveElements[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN].querySelector('input').checked = OPWrapperService.autoPlay.stopByWinAmount
    this._interactiveElements[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN].querySelector('input[type="number"]').value = OPWrapperService.autoPlay.winAmountForStop
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE].querySelector('input').checked = OPWrapperService.autoPlay.stopByBalanceIncreaseAmount
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE].querySelector('input[type="number"]').value = OPWrapperService.autoPlay.balanceIncreaseAmountForStop
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE].querySelector('input').checked = OPWrapperService.autoPlay.stopByBalanceDecreaseAmount
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE].querySelector('input[type="number"]').value = OPWrapperService.autoPlay.balanceDecreaseAmountForStop
    this._interactiveElements[this._eElementsTypes.EET_OPTION_ENABLE_QUICK_PLAY].querySelector('input').checked = OPWrapperService.autoPlay.quickPlay
  }

  toggle() {
    if (this.isHidden) {
      this.show();
    } else {
      this.hide();
    }
    return !this.isHidden;
  }

  tryActiveAutoPlay() {
    // if (!this._container.classList.contains('hidden')) OPWrapperService.autoPlay.active = true;
  }

  _setCountButtons() {
    this._countButtonTypes = Object.fromEntries(this._availableCounts.map((_, index) => [`EBT_COUNT_${index}`, `count_button_${index}`]));
    this._eButtonsTypes = { ...this._initialButtonTypes, ...this._countButtonTypes };
    this._eElementsTypes = { ...this._initialElementsTypes, ...Object.fromEntries(this._availableCounts.map((_, index) => [`EET_COUNT_BUTTON_${index}`, this._eButtonsTypes[`EBT_COUNT_${index}`]])) };
    this._interactiveElementsIds = null;
    let markup = ``;

    for (let i = 0; i < this._availableCounts.length; i++) {
      markup += `<button id=${this.interactiveElementsIds[this._eElementsTypes[`EET_COUNT_BUTTON_${i}`]]} data-count-index=${i}>
                   <span>${this._availableCounts[i] === Infinity ? '∞' : this._availableCounts[i]}</span>
                 </button>`;
    }

    this.setInnerHTML(this._eElementsTypes.EET_COUNT_BUTTON_CONTAINER, markup);
    this._saveInteractiveElements();
    this._eButtonsActions = {
      ...this._initialButtonsActions,
      ...Object.values(this._countButtonTypes).reduce((acc, type) => {
        acc[type] = () => this._onCountButtonClick(this.interactiveElements[type]);
        return acc;
      }, {}),
    }
    this.addActions();
  }

  _updateCountButtons() {
    Object.values(this._eButtonsTypes).forEach((buttonType) => {
      const selected = this.interactiveElements[buttonType];
      if (selected) selected.classList.remove('selected');
    })
    const prevSelected = this.interactiveElements[this._eElementsTypes[`EET_COUNT_BUTTON_${this._activeIndex}`]];
    if (prevSelected) prevSelected.classList.add('selected');
  }

  addActions() {
    super.addActions();

    if (this._optionsInited) return;
    this._optionsInited = true;

    this._interactiveElements[this._eElementsTypes.EET_OPTION_BONUS_GAME_WIN].querySelector('input').onclick = (e) => {
      OPWrapperService.autoPlay.stopByBonusGameWin = e.target.checked;
    }

    this._interactiveElements[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN].querySelector('input[type="checkbox"]').onclick = (e) => {
      OPWrapperService.autoPlay.stopByWinAmount = e.target.checked;
    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN].querySelector('input[type="number"]').onblur = (e) => {
      const value = this._validateInputValue(e.target.value);
      OPWrapperService.autoPlay.winAmountForStop = value;
      if (value) OPWrapperService.autoPlay.stopByWinAmount = value > 0;

    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN].querySelector('input[type="number"]').addEventListener('focus', function () {
      setTimeout(() => {
        this.select();
      }, 0);
    });

    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE].querySelector('input[type="checkbox"]').onclick = (e) => {
      OPWrapperService.autoPlay.stopByBalanceIncreaseAmount = e.target.checked;
    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE].querySelector('input[type="number"]').onblur = (e) => {
      const value = this._validateInputValue(e.target.value);
      OPWrapperService.autoPlay.balanceIncreaseAmountForStop = value;
      if (value) OPWrapperService.autoPlay.stopByBalanceIncreaseAmount = value > 0;
    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE].querySelector('input[type="number"]').addEventListener('focus', function () {
      setTimeout(() => {
        this.select();
      }, 0);
    });

    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE].querySelector('input[type="checkbox"]').onclick = (e) => {
      OPWrapperService.autoPlay.stopByBalanceDecreaseAmount = e.target.checked;
    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE].querySelector('input[type="number"]').onblur = (e) => {
      const value = this._validateInputValue(e.target.value);
      OPWrapperService.autoPlay.balanceDecreaseAmountForStop = value;
      if (value) OPWrapperService.autoPlay.stopByBalanceDecreaseAmount = value > 0;
    }
    this._interactiveElements[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE].querySelector('input[type="number"]').addEventListener('focus', function () {
      setTimeout(() => {
        this.select();
      }, 0);
    });

    this._interactiveElements[this._eElementsTypes.EET_OPTION_ENABLE_QUICK_PLAY].querySelector('input[type="checkbox"]').onclick = (e) => {
      OPWrapperService.autoPlay.quickPlay = e.target.checked;
    }
  }

  _validateInputValue(value) {
    value = +(value).replace(',', '.') || 0;
    if (value < 0) value = 0;
    return value
  }

  _onCountButtonClick(button) {
    OPWrapperService.autoPlay.activeCountIndex = button.dataset.countIndex;
  }

  _onStartClick() {
    OPWrapperService.autoPlay.active = true;
  }

  get activeIndex() {
    return this._activeIndex;
  }

  _getMarkup() {
    return `<div class="ui-container__auto_play_table__wrapper">
             <div class="ui-container__auto_play_table__wrapper__title">
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
               ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
              </span>
            </div>
            <div class="ui-container__auto_play_table__wrapper__options_list_label">
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTIONS_LIST_LABEL]}>
               ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTIONS_LIST_LABEL])}
              </span>
            </div>
            <div class="ui-container__auto_play_table__wrapper__options_list">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTION_BONUS_GAME_WIN]} class="ui-container__auto_play_table__wrapper__options_list__option">
                <label class="ui-container__auto_play_table__wrapper__options_list__option__left">
                 <input class="ui-container__auto_play_table__wrapper__options_list__option__left__checkbox" type="checkbox" name="" id="">
                 <span class="ui-container__auto_play_table__wrapper__options_list__option__left__text">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTION_BONUS_GAME_WIN])}
                 </span>
                </label>
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN]} class="ui-container__auto_play_table__wrapper__options_list__option">
                <label class="ui-container__auto_play_table__wrapper__options_list__option__left">
                 <input class="ui-container__auto_play_table__wrapper__options_list__option__left__checkbox" type="checkbox" name="" id="">
                 <span class="ui-container__auto_play_table__wrapper__options_list__option__left__text">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTION_WIN_MORE_THEN])}
                 </span>
                </label>
                <input class="ui-container__auto_play_table__wrapper__options_list__option__input" type="number">
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE]} class="ui-container__auto_play_table__wrapper__options_list__option">
                <label class="ui-container__auto_play_table__wrapper__options_list__option__left">
                 <input class="ui-container__auto_play_table__wrapper__options_list__option__left__checkbox" type="checkbox" name="" id="">
                 <span class="ui-container__auto_play_table__wrapper__options_list__option__left__text">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTION_BALANCE_INCREASE])}
                 </span>
                </label>
                <input class="ui-container__auto_play_table__wrapper__options_list__option__input" type="number">
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE]} class="ui-container__auto_play_table__wrapper__options_list__option">
                <label class="ui-container__auto_play_table__wrapper__options_list__option__left">
                 <input class="ui-container__auto_play_table__wrapper__options_list__option__left__checkbox" type="checkbox" name="" id="">
                 <span class="ui-container__auto_play_table__wrapper__options_list__option__left__text">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTION_BALANCE_DECREASE])}
                 </span>
                </label>
                <input class="ui-container__auto_play_table__wrapper__options_list__option__input" type="number">
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPTION_ENABLE_QUICK_PLAY]} class="ui-container__auto_play_table__wrapper__options_list__option">
                <label class="ui-container__auto_play_table__wrapper__options_list__option__left">
                 <input class="ui-container__auto_play_table__wrapper__options_list__option__left__checkbox" type="checkbox" name="" id="">
                 <span class="ui-container__auto_play_table__wrapper__options_list__option__left__text">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPTION_ENABLE_QUICK_PLAY])}
                 </span>
                </label>
              </div>
            </div>
            <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_COUNT_BUTTON_CONTAINER]} class="ui-container__auto_play_table__wrapper__button_block">
            </div>
            <div>
              <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_START_BUTTON]} class="ui-container__auto_play_table__wrapper__button_start" data-color="green">
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_START_BUTTON])}
              </button>
            </div>
            `
  }
}
