import BaseManagerHttp from './base/baseManagerHttp';

export default class ManagerHttp extends BaseManagerHttp {

  async getTournamentsAuthorizationToken() {
    const url = `https://${this.api}` + `/game_init?sid=${this.sid}&gid=${this.gid}`;
    return await this.getRequest(url).then(data => {
      if (!data) return;
      return data.tournaments;
    }).catch((error) => {
      console.error(error);
    });
  }
}
