import BaseController from '../../common/base';

export default class SubmitSuccess extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });

    this._eButtonsTypes = {
      EBT_OK: 'ok_button',
    };

    this._eElementsTypes = {
      EET_TITLE: 'title',
      EET_TEXT: 'text',
      EET_BUTTON_OK: this._eButtonsTypes.EBT_OK,
      EET_BUTTON_TEXT: 'button_text',
    };

    this._eEventTypes = {
      OK_CLICK: this.getEventName(this._eButtonsTypes.EBT_OK),
    };

    this.init(container);
    this.hide();
  }

  set localizations(data) {
    this._localizations = data;
    this._updateLocalizations();
  }

  set scaleData(data) {
    super.scaleData = data;
  }

  _getMarkup() {
    return `<div class="common-ui__submit_success">
              <div class="common-ui__submit_success__container_text">
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]} class="common-ui__submit_success__container_text__title"></span>
                <p id=${this.interactiveElementsIds[this._eElementsTypes.EET_TEXT]} class="common-ui__submit_success__container_text__text"></p>
              </div>
              
              <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_OK]}>
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M57 33.75C58.2426 33.75 59.25 34.7574 59.25 36C59.25 37.1391 58.4035 38.0805 57.3053 38.2295L57 38.25H15C13.7574 38.25 12.75 37.2426 12.75 36C12.75 34.8609 13.5965 33.9195 14.6947 33.7705L15 33.75H57Z" fill="white"/>
                  <path d="M28.409 19.409C29.2877 18.5303 30.7123 18.5303 31.591 19.409C32.3898 20.2078 32.4624 21.4578 31.8088 22.3386L31.591 22.591L16.591 37.591C15.7123 38.4697 14.2877 38.4697 13.409 37.591C12.6102 36.7922 12.5376 35.5422 13.1912 34.6614L13.409 34.409L28.409 19.409Z" fill="white"/>
                  <path d="M13.409 34.409C14.2078 33.6102 15.4578 33.5376 16.3386 34.1912L16.591 34.409L31.591 49.409C32.4697 50.2877 32.4697 51.7123 31.591 52.591C30.7922 53.3898 29.5422 53.4624 28.6614 52.8088L28.409 52.591L13.409 37.591C12.5303 36.7123 12.5303 35.2877 13.409 34.409Z" fill="white"/>
                </svg>
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_TEXT]}></span>
              </button>
            </div>`
  }
}
