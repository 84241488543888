import UserAgentDetector from '../utils/UserAgentDetector';
import { isIframe } from '../utils/isIframe';
import { disableDoubleTapToZoom } from '../utils/disableDoubleTapToZoom';
import VirtualKeyboardManager from '../scaleManager/VirtualKeyboardManager';

const MAX_PERCENT_OF_SCREEN_HEIGHT_FOR_MINIMIZED_UI = 6;

export default new class Fullscreen {
  constructor() {
    this._handleClick = this._handleClick.bind(this);
    this._handleFullscreenChange = this._handleFullscreenChange.bind(this);
    this._handleResize = this._handleResize.bind(this);

    this._handleTouchStart = this._handleTouchStart.bind(this);
    this._handleTouchMove = this._handleTouchMove.bind(this);
    this._handleTouchEnd = this._handleTouchEnd.bind(this);

    this._isDrag = false;

    this._logAllowFullscreen();

    if (UserAgentDetector.isMobile.any && !UserAgentDetector.isMobile.apple.phone && document.fullscreenEnabled) {
      window.addEventListener('pointerup', this._handleClick);
      window.addEventListener('fullscreenchange', this._handleFullscreenChange);
    } else if (UserAgentDetector.isMobile.apple.phone) {
      this._currentOrientation = null;
      this._isListeningTouchEvents = false;
      this._wrapGameContainer();
      this._initScrollContainer();
      visualViewport.addEventListener('resize', this._handleResize);
      this._handleResize();
    }
  }

  _wrapGameContainer() {
    const wrapper = document.createElement('div');
    wrapper.style.position = 'fixed';
    wrapper.style.top = '0';
    wrapper.style.right = '0';
    wrapper.style.bottom = '0';
    wrapper.style.left = '0';

    for (let element of document.body.children) {
      wrapper.appendChild(element);
    }

    document.body.appendChild(wrapper);
  }

  _initScrollContainer() {
    this._scrollContainer = document.createElement('div');
    this._scrollContainer.style.position = 'absolute';
    this._scrollContainer.style.width = '100%';
    this._scrollContainer.style.zIndex = '9999';
    this._scrollContainer.style.background = 'rgba(0,0,0,0.3)';
    this._scrollContainer.style.overflowY = 'auto';
    this._scrollContainer.style.height = '800vh';
    this._scrollContainer.style.touchAction = 'pan-y';
    disableDoubleTapToZoom(this._scrollContainer);

    const animationContainer = document.createElement('div');
    animationContainer.style.position = 'fixed';
    animationContainer.style.top = '50vh';
    animationContainer.style.left = '50%';
    animationContainer.style.transform = 'translate(-50%, -50%)';
    animationContainer.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFF"><path d="M245-400q-51-64-78-141t-27-159q0-27 3-54t9-54l-70 70-42-42 140-140 140 140-42 42-65-64q-7 25-10 50.5t-3 51.5q0 70 22.5 135.5T288-443l-43 43Zm413 273q-23 8-46.5 7.5T566-131L304-253l18-40q10-20 28-32.5t40-14.5l68-5-112-307q-6-16 1-30.5t23-20.5q16-6 30.5 1t20.5 23l148 407-100 7 131 61q7 3 15 3.5t15-1.5l157-57q31-11 45-41.5t3-61.5l-55-150q-6-16 1-30.5t23-20.5q16-6 30.5 1t20.5 23l55 150q23 63-4.5 122.5T815-184l-157 57Zm-90-265-54-151q-6-16 1-30.5t23-20.5q16-6 30.5 1t20.5 23l55 150-76 28Zm113-41-41-113q-6-16 1-30.5t23-20.5q16-6 30.5 1t20.5 23l41 112-75 28Zm8 78Z"/></svg>`
    this._animationElement = animationContainer.querySelector('svg');
    this._animationElement.style.position = 'relative';

    this._scrollContainer.append(animationContainer);
    document.body.append(this._scrollContainer);
  }

  _handleFullscreenChange() {
    if (this.isFullscreen) {
      window.removeEventListener('click', this._handleClick, { capture: true });
    } else {
      window.addEventListener('click', this._handleClick, { capture: true });
    }
  }

  _handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isFullscreen) return;
    this._openFullscreen();
  }

  _handleResize() {
    if (this._resizeRequest) clearTimeout(this._resizeRequest);
    if (this._scrollRequest) clearTimeout(this._scrollRequest)

    this._resizeRequest = setTimeout(() => {
      const portrait = window.matchMedia('(orientation: portrait)').matches;

      if (portrait && this._currentOrientation !== 'portrait') {
        this._removeScrollBlocker();
        this._currentOrientation = 'portrait';
      } else if (!portrait && this._currentOrientation !== 'landscape') {
        this._initialHeight = window.innerHeight;

        if (VirtualKeyboardManager.isVirtualKeyboardAffectsLayout) return;
        this._setupScrollBlocker();
        this._currentOrientation = 'landscape';
      }

      if (portrait) return;

      console.log('inner height: ', this._initialHeight);
      console.log('outer height: ', window.outerHeight);

      if (this._checkIsMinimalisticUi()) {
        console.log('remove blocker on resize');
        this._hideScrollBlocker();
        if (!this.isDrag) {
          this._removeTouchListeners();
        }
      } else if (window.innerHeight - this._initialHeight <= 0) {
        console.log('show blocker on resize');
        this._showScrollBlocker();
        if (!this.isDrag) {
          this._addTouchListeners();
        }
      }
    }, 0)
  }

  _handleTouchStart() {
    this._isDrag = true;
  }

  _handleTouchEnd() {
    this._isDrag = false;
    if (this._checkIsMinimalisticUi()) {
      this._hideScrollBlocker();
      this._removeTouchListeners();
    }
  }

  _handleTouchMove(e) {
    if (this._checkIsMinimalisticUi()) {
      console.log('hide blocker on move');
      this._hideScrollBlocker();
    } else {
      console.log('show blocker on move');
      this._showScrollBlocker();
    }
  }

  _checkIsMinimalisticUi() {
    const windowOuterInnerDiff = window.outerHeight - window.innerHeight;
    const percentageDiff = windowOuterInnerDiff * 100 / window.outerHeight;
    console.log('outer-inner screen percent diff: ', percentageDiff);
    return percentageDiff < MAX_PERCENT_OF_SCREEN_HEIGHT_FOR_MINIMIZED_UI;
  }

  _hideScrollBlocker() {
    this._scrollContainer.style.zIndex = '-1';
    this._stopAnimation();
  }

  _removeScrollBlocker() {
    this._scrollContainer.style.zIndex = '-1';
    document.body.style.overflowY = 'hidden';
    document.body.style.height = '100%'
    this._removeTouchListeners();
  }

  _showScrollBlocker() {
    this._scrollContainer.style.zIndex = '9999';
    this._addTouchListeners();
    this._startAnimation();

    if (!this.isDrag) this._scrollRequest = setTimeout(() => window.scrollBy({ top: -100 }), 500);
  }

  _setupScrollBlocker() {
    this._scrollContainer.style.zIndex = '9999';
    document.body.style.overflowY = 'unset';
    document.body.style.height = '800vh'
    this._addTouchListeners();
    this._scrollRequest = setTimeout(() => window.scrollBy({ top: -100 }), 500);
  }

  _addTouchListeners() {
    if (this._isListeningTouchEvents) return;
    window.addEventListener('touchstart', this._handleTouchStart);
    window.addEventListener('touchmove', this._handleTouchMove);
    window.addEventListener('touchend', this._handleTouchEnd);
    console.log('touch listeners attached');
    this._isListeningTouchEvents = true;
  }

  _removeTouchListeners() {
    if (!this._isListeningTouchEvents) return;
    window.removeEventListener('touchstart', this._handleTouchStart);
    window.removeEventListener('touchmove', this._handleTouchMove);
    window.removeEventListener('touchend', this._handleTouchEnd);
    console.log('touch listeners removed');
    this._isListeningTouchEvents = false;
  }

  _startAnimation() {
    if (this._swipeAnimation && this._swipeAnimation.playState === 'running') return;

    this._swipeAnimation = this._animationElement.animate([
      { transform: 'translateY(0)', opacity: 1 },
      { transform: `translateY(-50px) scale(1.6)`, opacity: 1 },
      { transform: `translateY(-50px) scale(2)`, opacity: 0 },
    ], {
      duration: 1500,
      easing: 'ease-out',
      iterations: Infinity,
    })
  }

  _stopAnimation() {
    if (this._swipeAnimation) {
      this._swipeAnimation.cancel();
    }
  }

  get isFullscreen() {
    return Boolean(document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement);
  }

  get isDrag() {
    return this._isDrag;
  }

  showButtonIfAndroid(withStroke) {
    console.error(`'showButtonIfAndroid' method is deprecated`);
  }

  _logAllowFullscreen() {
    if (isIframe() && document.featurePolicy && !document.featurePolicy.allowsFeature('fullscreen')) {
      console.warn('Game can\'t use fullscreen, please add allow="fullscreen" attribute to iframe element');
    }
  }

  _openFullscreen(callback) {
    let element = document.documentElement;
    const requestFullscreen = element.requestFullscreen
      || element.mozRequestFullScreen
      || element.webkitRequestFullscreen
      || element.msRequestFullscreen;

    const callFullscreen = () => {
      requestFullscreen && document.fullscreenEnabled && requestFullscreen.call(element, { navigationUI: 'hide' }).then(() => {
        if (callback) callback();
      }).catch(e => console.error(e));
    }

    clearTimeout(this._fullscreenTimeoutForFirstCall);
    this._fullscreenTimeoutForFirstCall = setTimeout(callFullscreen, 100); // hack: fullscreen can be called only after user action
  }

  _closeFullscreen(callback) {
    const exitFullscreen = document.exitFullscreen
      || document.mozCancelFullScreen
      || document.webkitExitFullscreen
      || document.msExitFullscreen;
    exitFullscreen && exitFullscreen.call(document).then(() => {
      if (callback) callback();
    }).catch(e => console.error(e));
  }
}
