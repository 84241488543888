import FlashLib from 'flashlib_onlyplay';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._lightsVisible = false;
    this._animationTypes = {
      BLINK: 'makeBlink',
      SNAKE: 'makeSnake',
      CIRCLE: 'makeCircle'
    };

    this.init();
    this.makeBinds();
  }

  init() {
    this.lights = [];
    const lightsContainer = this.getChildByName('lightsContainer');

    if (lightsContainer){
      lightsContainer.children.forEach(el => {
        el.visible = false;
        this.lights.push(el)
      });
    }
  }

  makeBinds() {
    this.makeBlink = this.makeBlink.bind(this);
    this.makeSnake = this.makeSnake.bind(this);
    this.makeCircle = this.makeCircle.bind(this);
  }

  playAnimation(type, duration = 2000, speed = 10, snakeCount = 6, loop = false) {
    if (!Object.values(this._animationTypes).includes(type)) return console.warn('Unknown lights animation type');

    this.animationTypePlayed = type;
    this.startTime = new Date();
    this.lastIterationTime = this.startTime;
    this.duration = duration;
    this.speed = speed;
    this.snakeCount = snakeCount;
    this.lastLightShowed = 0;
    this.order = 0;
    this.loop = loop;
    requestAnimationFrame(this[type]);
  }

  makeBlink() {
    const time = new Date();
    if (this.animationTypePlayed !== this.animationTypes.BLINK) return;
    if (Number(this.startTime) + this.duration <= time) {
      this.animationTypePlayed = null;
      this.hideLights();
      return this.playAnimation(this.animationTypes.CIRCLE, 400000, 1500);
    }

    if (time - this.lastIterationTime <= this.duration / this.speed) return requestAnimationFrame(this.makeBlink);
    this.lastIterationTime = time;
    if (this.lightsVisible) {
      this.hideLights();
    } else {
      this.showLights();
    }

    requestAnimationFrame(this.makeBlink);
  }

  makeSnake() {
    if (this.animationTypePlayed !== this.animationTypes.SNAKE) return;

    const time = new Date();
    if (Number(this.startTime) + this.duration <= time) {
      this.animationTypePlayed = null;
      this.hideLights();
      return this.playAnimation(this.animationTypes.CIRCLE, 400000, 1500);
    }

    if (time - this.lastIterationTime <= this.duration / this.speed) return requestAnimationFrame(this.makeSnake);
    this.lastIterationTime = time;
    this.hideLights();

    if (this.lastLightShowed === this.lights.length + this.snakeCount + 1) {
      this.lastLightShowed = 0;
    }
    const lightsToShow = this.lights.slice(this.lastLightShowed < this.snakeCount ? 0 : this.lastLightShowed - this.snakeCount, this.lastLightShowed + 1);

    this.lastLightShowed += 1;
    lightsToShow.forEach(el => el.visible = true);

    requestAnimationFrame(this.makeSnake);
  }

  makeCircle() {
    if (this.animationTypePlayed !== this.animationTypes.CIRCLE) return;

    const time = new Date();
    if (Number(this.startTime) + this.duration <= time && !this.loop) {
      this.animationTypePlayed = null;
      this.hideLights();
      // return this.playAnimation(this.animationTypes.BLINK, 4000, 40);
    }

    if (time - this.lastIterationTime <= this.duration / this.speed) return requestAnimationFrame(this.makeCircle);
    this.lastIterationTime = time;
    this.hideLights();

    const lightsToShow = this.lights.filter((el, i) => {
      if (this.order) {
        return i % 2 === 0;
      } else {
        return i % 2 !== 0;
      }
    });

    lightsToShow.forEach(el => el.visible = true);

    if (this.order) {
      this.order = 0;
    } else {
      this.order = 1;
    }

    requestAnimationFrame(this.makeCircle);
  }

  showLights(skip) {
    let lightsToShow = this.lights;
    if (skip) {
      lightsToShow = this.lights.filter((el, i) => i % skip === 0);
    }
    lightsToShow.forEach(el => el.visible = true);
    this.lightsVisible = true;
  }

  hideLights() {
    this.lights.forEach(el => el.visible = false);
    this.lightsVisible = false;
  }

  stop() {
    this.animationTypePlayed = null;
    this.hideLights();
  }

  get animationTypes() {
    return this._animationTypes;
  }

  set lightsVisible(value) {
    return this._lightsVisible = value;
  }

  get lightsVisible() {
    return this._lightsVisible;
  }
}
