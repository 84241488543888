import BaseController from '../basePlayerController';
import Stopwatch from '../../../../utils/Stopwatch';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerStopwatch extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.infoPanel.timePlayedTitle`,
    };

    this.init(container);
  }

  init(container) {
    super.init(container);
    Stopwatch.onUpdateTime.add(this._updateTime.bind(this));
  }

  _updateTime({timeFormat2}) {
    this.set(this._eElementsTypes.EET_VALUE, timeFormat2);
  }

  _getMarkup() {
    return `<span>
              <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
              </label>
              <span class="ui-container__info_panel__stopwatch_value" id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
                00:00:00
              </span>
            </span>`
  }
}
