export const whiteYelowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};

export const jackpotGrand = {
  fill: [
    "#ff0002",
    "#ff6a6f",
    "#ff6a6f",
    "#e2110d"
  ],
  fillGradientStops: [
    0.4,
    0.55,
    0.6
  ],
  lineJoin: "round",
  strokeThickness: 5
}
export const jackpotMega = {
  fill: [
    "#2fff3c",
    "#179526"
  ],
  fillGradientStops: [
    0.5,
    1
  ],
  lineJoin: "round",
  stroke: "#ffd71a",
  strokeThickness: 1
}
export const jackpotMajor = {
  fill: [
    "#fc00ff",
    "#900e90"
  ],
  fillGradientStops: [
    0.5,
    0.8
  ],
  lineJoin: "round",
  stroke: "#ffe616",
  strokeThickness: 1
}
export const jackpotMinor = {
  fill: [
    "#13fbd7",
    "aqua",
    "#099191"
  ],
  fillGradientStops: [
    0.2,
    0.6,
    0.9
  ],
  lineJoin: "round",
  stroke: "#d9da2d",
  strokeThickness: 1.5
}
export const jackpotMini = {
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 10,
  dropShadowDistance: 0,
  fill: [
    "#ee8434",
    "yellow",
    "yellow",
    "#ff7502"
  ],
  fillGradientStops: [
    0.3,
    0.5,
    0.6,
    0.8
  ],
  lineJoin: "round",
  stroke: "#d9da2d"
}
export const startScreenFontStyle = {
  dropShadow: true,
  dropShadowAlpha: 0.82,
  dropShadowAngle: 1.5,
  dropShadowBlur: 1,
  dropShadowDistance: 0.5,
  fill: [
    "#ffae5b",
    "#f4a918",
    "#fccf02",
    "#fccf02"
  ],
  fillGradientStops: [
    0.18,
    0.45,
    0.6,
    1
  ],
  lineJoin: "round",
  stroke: "#4a0000",
  strokeThickness: 4
}
export const wonJackpotGameFontStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 6,
  dropShadowColor: "#770707",
  dropShadowDistance: 10,
  fill: [
    "#f98c01",
    "#ffdc1c"
  ],
  lineJoin: "round",
  stroke: "#feffdf",
  strokeThickness: 2
}
export const winFontStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 16,
  dropShadowColor: "#4c0000",
  dropShadowDistance: 17,
  fill: [
    "#f94803",
    "#fb8b37",
    "yellow",
    "#ffff8e",
    "yellow",
    "#ff6e02"
  ],
  fillGradientStops: [
    0.22,
    0.3,
    0.4,
    0.55,
    0.72,
    0.83
  ],
  lineJoin: "round",
  stroke: "#4a0000",
  strokeThickness: 6
}
