import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import eEventTypes from '../enums/eEventTypes';

export default class ShowWinState extends BaseState {
    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_BIG_WIN__SKIP, this.stopNow, this);
    }

    stopNow() {
        if(this.active) {
            this.stop();
        }
    }

}
