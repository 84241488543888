import BaseController from './baseSystemController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBugReportPreloaderButton extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_OPEN_REPORT_FORM: 'open_report_form',
    };
    this._eEventTypes = {
      OPEN_FORM_CLICK: this.getEventName(this._eButtonsTypes.EBT_OPEN_REPORT_FORM),
    };
    this._eElementsTypes = {
      EET_OPEN_FORM_BUTTON: this._eButtonsTypes.EBT_OPEN_REPORT_FORM,
    };

    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_OPEN_REPORT_FORM]: () => {
        window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_BUG_REPORT, true);
      }
    };

    this._localizations = {
      [this._eElementsTypes.EET_OPEN_FORM_BUTTON]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.preloaderButton`
    }

    this.init(container);
  }

  _getMarkup() {
    return `<div class="system-ui-container__bug_report_preloader_button">
      <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_OPEN_FORM_BUTTON]}>
        ${this._getLocalization(this._localizations[this._eElementsTypes.EET_OPEN_FORM_BUTTON])}
      </button>
    </div>`
  }
}
