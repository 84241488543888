import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerLabel extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
    };
    this._eLabelsTypes = {
      ELT_GOOD_LUCK: 'label_good_luck',
      ELT_AWESOME: 'label_awesome',
      ELT_CLICK_ON_SPIN: 'label_click_on_spin',
    }
    this._localizations = Object.values(this._eLabelsTypes).reduce((prev, value) => {
      prev[value] = `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.${value}`
    return prev;
    }, {});
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL];

    this.init(container);
    this.setValue(this._eLabelsTypes.ELT_CLICK_ON_SPIN);
    // this.hide();
  }

  setValue(labelType) {
    this.set(this._eElementsTypes.EET_LABEL, this._getLocalization(this._localizations[labelType]));
  }

  _getMarkup() {
    return `<div class="ui-container__label">
          <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
            ${this._getLocalization(this._localizations[this._eLabelsTypes.ELT_CLICK_ON_SPIN])}
          </label>
        </div>`
  }

  get eLabelsTypes() {
    return this._eLabelsTypes;
  }
}
