import GlobalDispatcher from '../../events/GlobalDispatcher';
import SoundManager from "Engine/soundManager/SoundManager";
import EntryPoint from 'Engine/EntryPoint';

export default class BaseGameSettings {
    constructor() {
        this.keyName = 'onlyPlayGameSettings';

        let localData;
        try {
            localData = JSON.parse(localStorage.getItem(this.keyName || 'onlyPlayGameSettings'));
        } catch (e) {
            console.warn(e);
        }

        this.data = localData || {
            winningMute: false,
            soundMute: false,
            musicMute: false,
            quickSpin: false,
            introScreen: true
        };
    }

    init() {
        GlobalDispatcher.dispatch('gameSettings:init');
        EntryPoint.GameSettings = this;
        this.addListeners();

        for (let param in this.data) {
            this[param] = this.data[param];
        }
    }

    addListeners() {

    }

    get soundMute() {
        return this._soundMute;
    }

    set soundMute(value) {
        this._soundMute = value;
        SoundManager.muteGroup('default', this.soundMute);
        GlobalDispatcher.dispatch('gameSettings:soundMute', this.soundMute);

        this.data.soundMute = this.soundMute;
        try {
            localStorage.setItem(this.keyName, JSON.stringify(this.data));
        } catch (e) {
            console.warn(e);
        }
    }

    get winningMute() {
        return this._winningMute;
    }

    set winningMute(value) {
        this._winningMute = value;
        SoundManager.muteGroup('winning', this.winningMute);
        GlobalDispatcher.dispatch('gameSettings:winningMute', this.winningMute);

        this.data.winningMute = this.winningMute;
        try {
            localStorage.setItem(this.keyName, JSON.stringify(this.data));
        } catch (e) {
            console.warn(e);
        }
    }

    get musicMute() {
        return this._musicMute;
    }

    set musicMute(value) {
        this._musicMute = value;
        SoundManager.muteGroup('music', this.musicMute);
        GlobalDispatcher.dispatch('gameSettings:musicMute', this.musicMute);

        this.data.musicMute = this.musicMute;
        try {
            localStorage.setItem(this.keyName, JSON.stringify(this.data));
        } catch (e) {
            console.warn(e);
        }
    }

    get quickSpin() {
        return this._quickSpin;
    }

    set quickSpin(value) {
        this._quickSpin = value;
        GlobalDispatcher.dispatch('gameSettings:quickSpin', this.quickSpin);

        this.data.quickSpin = this.quickSpin;
        try {
            localStorage.setItem(this.keyName, JSON.stringify(this.data));
        } catch (e) {
            console.warn(e);
        }
    }

    get introScreen() {
        return this._introScreen;
    }

    set introScreen(value) {
        this._introScreen = value;
        GlobalDispatcher.dispatch('gameSettings:introScreen', this.introScreen);

        this.data.introScreen = this.introScreen;
        try {
            localStorage.setItem(this.keyName, JSON.stringify(this.data));
        } catch (e) {
            console.warn(e);
        }
    }
}
