import EntryPoint from '../../../EntryPoint';
import GlobalDispatcher from '../../../events/GlobalDispatcher';
import SoundManager from '../../../soundManager/SoundManager';
import eEventTypes from '../../../enums/eBaseEventTypes';

export default class BaseSystemUiAdapter {
  constructor(ControllerSystemUi) {
    this._controllerSystemUi = ControllerSystemUi;
    this._initConfig = {};
    EntryPoint.SystemUiAdapter = this;

    this.onBackgroundSoundSettingsChange = this.onBackgroundSoundSettingsChange.bind(this);
    this.onGameSoundSettingsChange = this.onGameSoundSettingsChange.bind(this);
    this.onWinSoundSettingsChange = this.onWinSoundSettingsChange.bind(this);

    this.addListeners();
  }

  init(config) {
    this._initConfig = config || this._initConfig;
    this._buttonClickSound = this._initConfig.clickSound;

    this.ControllerUi.toggleBackgroundSoundSettings(!EntryPoint.GameSettings.musicMute);
    this.ControllerUi.toggleGameSoundSettings(!EntryPoint.GameSettings.soundMute);
    this.ControllerUi.toggleWinSoundSettings(!EntryPoint.GameSettings.winningMute);
    this.addActions();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__MUSIC_MUTE, ({ params: muted }) => this.ControllerUi.toggleBackgroundSoundSettings(!muted));
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__SOUND_MUTE, ({ params: muted }) => this.ControllerUi.toggleGameSoundSettings(!muted));
    GlobalDispatcher.add(eEventTypes.EBET_SETTINGS__WINNING_MUTE, ({ params: muted }) => this.ControllerUi.toggleWinSoundSettings(!muted));
  }

  addActions() {
    const controllerTypes = this.ControllerUi.controllerTypes;
    const events = this.ControllerUi.events;

    if (events[controllerTypes.ECT_GAME_SETTINGS]) {
      this.ControllerUi.on(events[controllerTypes.ECT_GAME_SETTINGS].BACKGROUND_SOUND_CHANGE, this.onBackgroundSoundSettingsChange);
      this.ControllerUi.on(events[controllerTypes.ECT_GAME_SETTINGS].GAME_SOUND_CHANGE, this.onGameSoundSettingsChange);
      this.ControllerUi.on(events[controllerTypes.ECT_GAME_SETTINGS].WIN_SOUND_CHANGE, this.onWinSoundSettingsChange);
    }

    const addClickSoundRecursively = (event) => {
      if (typeof event === 'object') return Object.values(event).forEach((value) => addClickSoundRecursively(value));
      if (/:click$/.test(event)) this.ControllerUi.on(event, this.playClickSound.bind(this));
    }

    addClickSoundRecursively(events);
  }

  onBackgroundSoundSettingsChange(enabled) {
    EntryPoint.GameSettings.musicMute = !enabled;
  }

  onGameSoundSettingsChange(enabled) {
    EntryPoint.GameSettings.soundMute = !enabled;
  }

  onWinSoundSettingsChange(enabled) {
    EntryPoint.GameSettings.winningMute = !enabled;
  }

  playClickSound() {
    if (this._buttonClickSound) {
      SoundManager.play(this._buttonClickSound.soundName, this._buttonClickSound.volume, false, this._buttonClickSound.group);
    }
  }

  get ControllerUi() {
    return this._controllerSystemUi;
  }
}
