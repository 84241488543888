export  default  {
  EST_REEL_STOP: 'ReelStop1',
  EST_SCATTER_STOP: 'ScatterStop',
  EST_BIG_WIN: 'BigWin',
  EST_MEGA_WIN: 'SuperWin',
  EST_EPIC_WIN: 'SuperMegaBigWin',
  EST_BUTTON_CLICK: 'ButtonClick',
  EST_MONEY_GAIN: 'MoneyGain_low',
  EST_TILE: 'Tile',
  EST_LIGHT_WIN: 'lightWin',
}
