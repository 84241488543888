import FlashLib from 'flashlib_onlyplay';
import MoneyFormat from "Engine/base/moneyFormat/MoneyFormat";
import { shadowText } from 'Engine/utils/fontStyles';

export default class BaseBalanceContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.valueText = null;

        this.init();
    }

    init() {
        this.titleText = this.getChildByName('ui_balance_label');
        this.valueText = this.getChildByName('ui_balance_value');
    }

    setValue(value) {
        this.valueText.text = MoneyFormat.format(value, true);
        this.valueText.fitSize();
        this.valueText.correctPosition('center', 'center');
    }
}
