export default {
  ECT_AUTO_SPIN_COUNT: 'controller_auto_spin_count',
  ECT_BALANCE: 'controller_balance',
  ECT_LABEL: 'controller_label',
  ECT_BET: 'controller_bet',
  ECT_WIN: 'controller_win',
  ECT_INFO: 'controller_info',
  ECT_MENU: 'controller_menu',
  // ECT_FULL_SCREEN: 'controller_full_screen',
  ECT_TOTAL_WIN: 'controller_total_win',
  ECT_SPIN: 'controller_spin',
  ECT_AUTO_SPIN: 'controller_auto_spin',
  ECT_TURBO_SPIN: 'controller_turbo_spin',
  ECT_SOUND: 'controller_sound',
  ECT_BET_SELECT: 'controller_bet_selector',
  ECT_BET_SELECTOR_TABLE: 'controller_selector_table',
  ECT_AUTO_PLAY_TABLE: 'controller_auto_play_table',
  ECT_TOURNAMENTS_LABEL: 'controller_tournaments_label',
  ECT_WIN_LINE_LABEL: 'controller_win_line_label',
  ECT_FREESPINS_COUNT: 'controller_freespins_count',

  ECT_INFO_PANEL: 'controller_info_panel',
  ECT_INFO_PANEL_CLOCK: 'controller_info_panel_clock',
  // ECT_HONESTY: 'controller_honesty',
  // ECT_TOURNAMENTS: 'controller_tournaments',
}
