import BaseController from '../basePlayerController';
import ControllerDisplayRTP from './ControllerDisplayRTP';
import ControllerStopwatch from './ControllerStopwatch';
import ControllerNetPosition from './ControllerNetPosition';
import ControllerGameState from './ControllerGameState';
import eInfoPanelControllerTypes from './eInfoPanelControllerTypes';

export default class ControllerInfoPanel extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eControllersClasses = {
      [this.controllerTypes.EIT_STOPWATCH]: ControllerStopwatch,
      [this.controllerTypes.EIT_GAME_STATE]: ControllerGameState,
      [this.controllerTypes.EIT_NET_POSITION]: ControllerNetPosition,
      [this.controllerTypes.EIT_DISPLAY_RTP]: ControllerDisplayRTP,
    }

    this._needInitControllers = {
      [this.controllerTypes.EIT_STOPWATCH]: OPWrapperService.config.infoPanel.showStopwatch,
      [this.controllerTypes.EIT_GAME_STATE]: OPWrapperService.config.infoPanel.showGameState,
      [this.controllerTypes.EIT_NET_POSITION]: OPWrapperService.config.infoPanel.showNetPosition,
      [this.controllerTypes.EIT_DISPLAY_RTP]: OPWrapperService.config.infoPanel.displayRtp,
    }

    this._eElementsTypes = {};

    Object.values(this.controllerTypes)
      .forEach(infoType => {
        if (!this._needInitControllers[infoType]) {
          this._excludedControllerTypes.add(infoType);
        } else {
          this._eElementsTypes[infoType] = infoType;
        }
      });

    this.init(container);
    // this.hide();
  }

  get controllerTypes() {
    return eInfoPanelControllerTypes;
  }

  _tryCreateMarkupItemByInfoType(infoType) {
    if (!this._excludedControllerTypes.has(infoType)) {
      return `<span id="${this.interactiveElementsIds[infoType]}"></span>`
    } else {
      return '';
    }
  }

  _getMarkup() {
    return `
    ${this._tryCreateMarkupItemByInfoType(this.controllerTypes.EIT_STOPWATCH)}
    ${this._tryCreateMarkupItemByInfoType(this.controllerTypes.EIT_GAME_STATE)}
    ${this._tryCreateMarkupItemByInfoType(this.controllerTypes.EIT_NET_POSITION)}
    ${this._tryCreateMarkupItemByInfoType(this.controllerTypes.EIT_DISPLAY_RTP)}
    `
  }
}
