import Event from '../events/event';
export default new class Stopwatch {
  constructor(container) {
    this.startTime = Date.now();
    setInterval(this._updateTime.bind(this), 1000);
    this.onUpdateTime = new Event();
  }

  _updateTime() {
    const timeDifference = Date.now() - this.startTime
    const seconds = Math.floor(timeDifference / 1000 % 60);
    const minutes = Math.floor(timeDifference / 1000 / 60 % 60);
    const hours = Math.floor(timeDifference / 1000 / 60 / 60);
    const secondsText = `${this.formattingTime(seconds)}s`;
    const minutesText = hours || minutes ? `${this.formattingTime(minutes)}m` : '';
    const hoursText = hours ? `${this.formattingTime(hours)}h` : '';
    const timeDifferenceText = `${hoursText} ${minutesText} ${secondsText}`
    const timeFormat2 = `${this.formattingTime(hours)}:${this.formattingTime(minutes)}:${this.formattingTime(seconds)}`;
    this.onUpdateTime.call({timeDifference, timeDifferenceText, hours, minutes, seconds, hoursText, minutesText, secondsText, timeFormat2});
  }

  formattingTime(time) {
    return time >= 10 ? time : `0${time}`;
  }
}
