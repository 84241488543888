import BaseController from '../basePlayerController';

export default class ControllerClock extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_CLOCK: 'clock',
    };

    this.init(container);
    this._startUpdate();
    // this.hide();
  }

  show() {
    super.show();
    this._startUpdate();
  }
  hide() {
    super.hide();
    clearInterval(this._clockInterval);
  }

  _startUpdate() {
    if (!this._clockInterval) {
      this._clockInterval = setInterval(this._updateTime.bind(this), 1000);
      this._updateTime();
    }
  }

  _updateTime() {
    this.set(this._eElementsTypes.EET_CLOCK, new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
  }

  _getMarkup() {
    return `<div class="ui-container__clock">
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOCK]}>
          </span>
        </div>`
  }
}
