import BaseController from './basePlayerController';

export default class ControllerBetSelect extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_SHOW_BETS: 'show_bets_button',
      // EBT_HIDE_BETS: 'hide_bets_button',
    };
    this._eEventTypes = {
      SHOW_BETS_CLICK: 'show_bets',
      HIDE_BETS_CLICK: 'hide_bets',
      // HIDE_BETS_CLICK: this.getEventName(this._eButtonsTypes.EBT_HIDE_BETS),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_SHOW_BETS]: (e) => {
        if (e.currentTarget.classList.contains('toggled_on')) {
          this.emit(this._eEventTypes.HIDE_BETS_CLICK);
          this._showOpenButton();
        } else {
          this.emit(this._eEventTypes.SHOW_BETS_CLICK);
          this._showCloseButton();
        }
      },
      // [this._eButtonsTypes.EBT_HIDE_BETS]: this._showOpenButton.bind(this),
    };
    this._eElementsTypes = {
      EET_SHOW_BETS_BUTTON: this._eButtonsTypes.EBT_SHOW_BETS,
      // EET_HIDE_BETS_BUTTON: this._eButtonsTypes.EBT_HIDE_BETS,
    };

    this.init(container);
    this._showOpenButton();
  }

  reset() {
    this._showOpenButton();
  }

  _showOpenButton() {
    this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.remove('toggled_on');
    // this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.remove('hidden');
    // this.interactiveElements[this._eElementsTypes.EET_HIDE_BETS_BUTTON].classList.add('hidden');
  }

  _showCloseButton() {
    this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.add('toggled_on');
    // this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.add('hidden');
    // this.interactiveElements[this._eElementsTypes.EET_HIDE_BETS_BUTTON].classList.remove('hidden');
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SHOW_BETS_BUTTON]} ${this._container.dataset.orientation === 'landscape' ? '' : 'data-color="primary"'}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6641 35.7344C19.7686 35.7344 20.6641 36.6298 20.6641 37.7344C20.6641 44.5676 27.1031 50.001 35.9974 50.001C44.8917 50.001 51.3307 44.5676 51.3307 37.7344C51.3307 36.6298 52.2262 35.7344 53.3307 35.7344C54.4353 35.7344 55.3307 36.6298 55.3307 37.7344V47.601C55.3307 57.2795 46.403 64.6677 35.9974 64.6677C25.5917 64.6677 16.6641 57.2795 16.6641 47.601V37.7344C16.6641 36.6298 17.5595 35.7344 18.6641 35.7344ZM20.6671 47.8607C20.8286 54.7137 27.3652 60.6677 35.9974 60.6677C44.6296 60.6677 51.1662 54.7137 51.3277 47.8607C47.7201 51.7393 42.0638 54.001 35.9974 54.001C29.931 54.001 24.2747 51.7393 20.6671 47.8607Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.9974 11.332C31.6637 11.332 27.8154 12.848 25.0459 15.2654L25.045 15.2662C22.3008 17.6586 20.6641 20.8916 20.6641 24.3987C20.6641 26.4507 21.2257 28.3307 22.2106 29.9425L22.213 29.9464C24.6289 33.9202 29.7656 36.6654 35.9974 36.6654C42.2292 36.6654 47.3659 33.9202 49.7818 29.9464L49.7842 29.9425C50.7691 28.3307 51.3307 26.4507 51.3307 24.3987C51.3307 20.8957 49.6974 17.6614 46.9494 15.2392C44.1773 12.846 40.328 11.332 35.9974 11.332ZM22.4164 12.2512C25.9402 9.17571 30.7316 7.33203 35.9974 7.33203C41.2632 7.33203 46.0512 9.17554 49.572 12.219L49.5856 12.2309C53.0735 15.3014 55.3307 19.5846 55.3307 24.3987C55.3307 27.1993 54.5594 29.7985 53.1985 32.0263C49.9073 37.438 43.3114 40.6654 35.9974 40.6654C28.6834 40.6654 22.0876 37.438 18.7964 32.0265C17.4354 29.7986 16.6641 27.1993 16.6641 24.3987C16.6641 19.5862 18.9202 15.2996 22.4155 12.252" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.9974 11.332C27.2564 11.332 20.6641 17.4372 20.6641 24.3987V37.732C20.6641 44.5653 27.1031 49.9987 35.9974 49.9987C44.8917 49.9987 51.3307 44.5653 51.3307 37.732V24.3987C51.3307 20.8957 49.6974 17.6614 46.9494 15.2392C44.1773 12.846 40.328 11.332 35.9974 11.332ZM16.6641 24.3987C16.6641 14.7203 25.5917 7.33203 35.9974 7.33203C41.2632 7.33203 46.0512 9.17554 49.572 12.219L49.5856 12.2309C53.0735 15.3014 55.3307 19.5846 55.3307 24.3987V37.732C55.3307 47.5388 46.2497 53.9987 35.9974 53.9987C25.745 53.9987 16.6641 47.5388 16.6641 37.732V24.3987Z" fill="white"/>
          </svg>
          </button>`
  }
}

// <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_HIDE_BETS_BUTTON]} data-color="cancel">
//   <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M22.1602 35.839L35.8385 22.1606" stroke="white" stroke-width="3.625" stroke-linecap="round"
//           stroke-linejoin="round" />
//     <path d="M35.8385 35.839L22.1602 22.1606" stroke="white" stroke-width="3.625" stroke-linecap="round"
//           stroke-linejoin="round" />
//     <path
//       d="M21.7487 53.1668H36.2487C48.332 53.1668 53.1654 48.3335 53.1654 36.2502V21.7502C53.1654 9.66683 48.332 4.8335 36.2487 4.8335H21.7487C9.66536 4.8335 4.83203 9.66683 4.83203 21.7502V36.2502C4.83203 48.3335 9.66536 53.1668 21.7487 53.1668Z"
//       stroke="white" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
//   </svg>
// </button>
