import BaseController from './basePlayerController';

export default class ControllerAutoPlayButton extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_AUTO_SPIN: 'auto_spin_button',
    };
    this._eEventTypes = {
      AUTO_SPIN_CLICK: this.getEventName(this._eButtonsTypes.EBT_AUTO_SPIN),
    };
    this._eElementsTypes = {
      EET_AUTO_SPIN_BUTTON: this._eButtonsTypes.EBT_AUTO_SPIN,
    };

    this.init(container);
  }

  toggleActive(value) {
    if (value) {
      this.interactiveElements[this._eElementsTypes.EET_AUTO_SPIN_BUTTON].classList.add('toggled_on');
    } else {
      this.interactiveElements[this._eElementsTypes.EET_AUTO_SPIN_BUTTON].classList.remove('toggled_on');
    }
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_AUTO_SPIN_BUTTON]} class="auto-play-button" ${this._container.dataset.orientation === 'landscape' ? '' : 'data-color="primary"'}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.4586 43.7771H28.9201L34.3118 28.1587H38.5672L43.9513 43.7771H40.4127L36.5005 31.7277H36.3785L32.4586 43.7771ZM32.2375 37.638H40.5958V40.2157H32.2375V37.638Z" fill="white"/>
            <path d="M50.5998 34.5913C51.3097 33.881 52.4208 33.8162 53.2039 34.397L53.4283 34.5906L57.3504 38.5091L61.2756 34.5902C61.986 33.8805 63.0971 33.8165 63.8798 34.3979L64.104 34.5916C64.8137 35.302 64.8777 36.4132 64.2963 37.1958L64.1026 37.42L58.7639 42.7534C58.0539 43.4627 56.9435 43.527 56.1608 42.9465L55.9365 42.753L50.6005 37.4197C49.8193 36.6388 49.819 35.3725 50.5998 34.5913Z" fill="white"/>
            <path d="M36.0062 12.6667C48.9005 12.6667 59.3528 23.1146 59.3528 36.0054C59.3528 37.7227 59.152 39.4379 58.7654 41.1564C58.523 42.234 57.4529 42.9111 56.3752 42.6687C55.2976 42.4262 54.6205 41.3561 54.8629 40.2785C55.1863 38.8408 55.3528 37.419 55.3528 36.0054C55.3528 25.3241 46.6917 16.6667 36.0062 16.6667C30.803 16.6667 25.9319 18.7266 22.3239 22.3321C21.5426 23.1129 20.2762 23.1125 19.4955 22.3311C18.7147 21.5498 18.7151 20.2835 19.4964 19.5027C23.847 15.1552 29.7316 12.6667 36.0062 12.6667Z" fill="white"/>
            <path d="M13.2377 29.2465C13.9477 28.5371 15.0581 28.4728 15.8408 29.0533L16.065 29.2468L21.401 34.5801C22.1823 35.361 22.1826 36.6273 21.4017 37.4086C20.6919 38.1188 19.5808 38.1836 18.7977 37.6029L18.5733 37.4093L14.6485 33.488L10.726 37.4096C10.0156 38.1193 8.90447 38.1833 8.1218 37.602L7.89758 37.4082C7.18789 36.6978 7.1239 35.5867 7.70523 34.804L7.89899 34.5798L13.2377 29.2465Z" fill="white"/>
            <path d="M13.2359 30.844C13.4783 29.7663 14.5484 29.0892 15.626 29.3317C16.7037 29.5741 17.3808 30.6442 17.1383 31.7218C16.8149 33.1595 16.6484 34.5813 16.6484 35.9949C16.6484 46.6762 25.3095 55.3336 35.9951 55.3336C41.1982 55.3336 46.0694 53.2737 49.6774 49.6682C50.4587 48.8874 51.725 48.8879 52.5058 49.6692C53.2866 50.4505 53.2861 51.7168 52.5048 52.4976C48.1543 56.8452 42.2697 59.3336 35.9951 59.3336C23.1008 59.3336 12.6484 48.8857 12.6484 35.9949C12.6484 34.2777 12.8493 32.5624 13.2359 30.844Z" fill="white"/>
          </svg>
        </button>`
  }
}
