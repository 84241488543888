import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import eEventTypes from '../enums/eEventTypes';

export default class StopWinState extends BaseState {
    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED, this.stopNow, this);
    }

    stopNow() {
        if (this.active) {
            this.complete();
        }
    }

    complete() {
        GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
        super.complete();
    }

    stop() {
        GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
        super.stop();
    }
}
