export default class BaseManagerHttp {
  constructor({ api, sid, gid }) {
    this.api = api;
    this.sid = sid;
    this.gid = gid;
    this.mode = /\.dev\./.test(this.api) ? 'dev' : /\.stage\./.test(this.api) ? 'stage' : '';
  }

  async postRequest (url, data, options, retryCount = 0, retryDelay = 1000) {
    const init = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json',
      },
      ...options,
    };
    return this._request(url, init, retryCount, retryDelay);
  };

  async getRequest (url, options, retryCount = 0, retryDelay = 1000) {
    const init = {
      method: 'GET',
      ...options,
    };

    return this._request(url, init, retryCount, retryDelay);
  };

  async _request(url, init, retryCount = 0, retryDelay = 1000) {
    const rawResponse = await fetch(url, init);

    let response;
    if (rawResponse.ok) {
      response = await this._parseResponse(rawResponse);
    }

    if ((!rawResponse.ok || !response.success) && retryCount > 0) {
      await new Promise(resolve => setTimeout(resolve, retryDelay)); // Delay before retrying
      return this._request(url, init, retryCount - 1, retryDelay)
    } else if (!rawResponse.ok) {
      await this._manageFetchFailure(rawResponse);
    } else {
      this._validateResponse(response);
    }

    return response;
  }

  async _parseResponse(rawResponse) {
    return await rawResponse.json()
      .catch((error) => {
        console.error('Response: ', rawResponse);
        throw error;
      });
  }

  async _manageFetchFailure(rawResponse) {
    let body;
    try {
      body = await rawResponse.text();
    } catch (e) {

    }
    throw new Error(JSON.stringify({
      status: rawResponse.status,
      statusText: rawResponse.statusText,
      body: body
    }))  }

  _validateResponse(response) {
    if (!response.success) {
      throw response;
    }
  }
}
