import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBet extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_DECREASE_BUTTON: 'decrease_button',
      EBT_INCREASE_BUTTON: 'increase_button',
    };
    this._eEventTypes =  {
      DECREASE_BUTTON_CLICK: this.getEventName(this._eButtonsTypes.EBT_DECREASE_BUTTON),
      INCREASE_BUTTON_CLICK: this.getEventName(this._eButtonsTypes.EBT_INCREASE_BUTTON),
    };
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
      EET_DECREASE_BUTTON: this._eButtonsTypes.EBT_DECREASE_BUTTON,
      EET_INCREASE_BUTTON: this._eButtonsTypes.EBT_INCREASE_BUTTON,
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.bet_label`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this._availableBets = [];
    this._currentBetIndex = -1;
    this.init(container);
  }

  setBets(availableBets) {
    this._availableBets = availableBets;
  }

  setCurrentBetIndex(index) {
    if (index < 0 || index >= this._availableBets.length) return;
    this._currentBetIndex = index;
    this.set(this._eElementsTypes.EET_VALUE, this._moneyFormat(this._availableBets[index]));
  }

  get maxIndex() {
    return this._availableBets.length - 1;
  }

  _getMarkup() {
    return `
          <button class="ui-container__controller_bet__decrease_button" id=${this.interactiveElementsIds[this._eElementsTypes.EET_DECREASE_BUTTON]}>
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M46.6666 34C47.7712 34 48.6666 34.8954 48.6666 36C48.6666 37.0125 47.9142 37.8493 46.938 37.9817L46.6666 38H25.3333C24.2287 38 23.3333 37.1046 23.3333 36C23.3333 34.9875 24.0857 34.1507 25.0619 34.0183L25.3333 34H46.6666Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2634 10.0104L36 10C50.0751 10 61.5826 21.198 61.9896 35.2634L62 36C62 50.0751 50.802 61.5826 36.7366 61.9896L36 62C21.9249 62 10.4174 50.802 10.0104 36.7366L10 36C10 21.9249 21.198 10.4174 35.2634 10.0104ZM36 14L35.3502 14.0094C23.4754 14.3532 14 24.0904 13.9998 35.9711L14.0094 36.6498C14.3532 48.5246 24.0904 58 35.9711 58.0002L36.6498 57.9906C48.5246 57.6468 58 47.9096 58.0002 36.0289L57.9906 35.3502C57.6468 23.4754 47.9096 14 36 14Z" fill="white"/>
            </svg>
          </button>
          <div class="ui-container__title-desc-col">
            <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
              ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
            </label>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
              0.12345678
            </span>
          </div>
          <button class="ui-container__controller_bet__increase_button" id=${this.interactiveElementsIds[this._eElementsTypes.EET_INCREASE_BUTTON]}>
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36 23.3333C37.0125 23.3333 37.8493 24.0857 37.9817 25.0619L38 25.3333V46.6666C38 47.7712 37.1046 48.6666 36 48.6666C34.9875 48.6666 34.1507 47.9142 34.0183 46.938L34 46.6666V25.3333C34 24.2287 34.8954 23.3333 36 23.3333Z" fill="white"/>
              <path d="M46.6666 34C47.7712 34 48.6666 34.8954 48.6666 36C48.6666 37.0125 47.9142 37.8493 46.938 37.9817L46.6666 38H25.3333C24.2287 38 23.3333 37.1046 23.3333 36C23.3333 34.9875 24.0857 34.1507 25.0619 34.0183L25.3333 34H46.6666Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2634 10.0104L36 10C50.0751 10 61.5826 21.198 61.9896 35.2634L62 36C62 50.0751 50.802 61.5826 36.7366 61.9896L36 62C21.9249 62 10.4174 50.802 10.0104 36.7366L10 36C10 21.9249 21.198 10.4174 35.2634 10.0104ZM36 14L35.3502 14.0094C23.4754 14.3532 14 24.0904 13.9998 35.9711L14.0094 36.6498C14.3532 48.5246 24.0904 58 35.9711 58.0002L36.6498 57.9906C48.5246 57.6468 58 47.9096 58.0002 36.0289L57.9906 35.3502C57.6468 23.4754 47.9096 14 36 14Z" fill="white"/>
            </svg>
          </button>`
  }
}
