import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import SpinTimeManager from "Engine/base/spinTime/SpinTimeManager";

export default class IdleState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    start() {
        window.OPWrapperService.freeBetsController.updateTotalWin();
        if (!EntryPoint.GameModel.isFreeSpinsMode && !EntryPoint.GameModel.isBonusGameMode) {
            window.OPWrapperService.freeBetsController.show();
            window.OPWrapperService.realityCheck.blockedRealityCheck = false;
        }
        if (
          window.OPWrapperService.freeBetsController.isFirstFreeBet
          || window.OPWrapperService.freeBetsController.isLastFreeBet
          || window.OPWrapperService.realityCheck.blockedGame
        ) EntryPoint.AutoPlaySettings.active = false;
        super.start();
        EntryPoint.GameModel.respinReelIndex = null;
        if (EntryPoint.AutoPlaySettings.active && EntryPoint.GameModel.bet > EntryPoint.GameModel.balance  &&
          !EntryPoint.GameModel.isSpinWithoutBalanceCheck) {
            EntryPoint.AutoPlaySettings.active = false;
            window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
            return;
        } else if ((EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) || EntryPoint.GameModel.isFreeSpinsMode) {
            this.timeout = setTimeout(()=>{
                this.stop();
            }, SpinTimeManager.timeLeft);
        }
    }

    /*stop() {
        if(GameModel.checkStartSpin()) {
            super.stop();
        }
    }*/
    onEnd() {
        clearTimeout(this.timeout);
        super.onEnd();
    }
}
