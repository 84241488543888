import BaseController from '../../common/base';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';
import { getFileSizeMB, validateFileExtension } from '../../../../utils/files';

export default class FileDrop extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });

    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_INPUT: 'input',
      EET_PREVIEW: 'preview',
      EET_PLACEHOLDER: 'placeholder',
      EET_PLACEHOLDER_TITLE: 'placeholder_title',
      EET_PLACEHOLDER_DESCRIPTION: 'placeholder_description',
    };

    this._eEventTypes = {
      FILE_LOADED: 'file_loaded',
    };

    this._localizations = {
      [this._eElementsTypes.EET_PLACEHOLDER_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.common.file_drop.choose_file`,
      [this._eElementsTypes.EET_PLACEHOLDER_DESCRIPTION]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.common.file_drop.accept_images`,
    }

    this._accept = 'image/*';
    this._maxFileSize = 10; //mb
    this._initialState = {
      dragCounter: 0,
      isDragging: false,
      file: null,
    }
    this._state = { ...this._initialState };

    this.init(container);
  }

  _afterInit() {
    super._afterInit();
    const label = this.interactiveElements[this._eElementsTypes.EET_LABEL];
    const input = this.interactiveElements[this._eElementsTypes.EET_INPUT];

    input.onchange = (e) => {
      const files = e.target.files;
      if (!files.length) {
        this._state.file = null;
        return;
      }

      const file = files[0];
      if (!this._validateFile(file)) return;

      this._state.file = file;
      this.showPreview();
      this.emit(this._eEventTypes.FILE_LOADED, file);
    }

    label.ondragenter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this._state.dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        this._state.isDragging = true;
        if (this._validateFile(e.dataTransfer.items[0])) {
          label.classList.add('common-ui__file_drop__active');
        }
      }
    }

    label.ondragover = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }

    label.ondragleave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this._state.dragCounter--;
      if (this._state.dragCounter === 0) {
        this._state.isDragging = false;
        label.classList.remove('common-ui__file_drop__active');
      }
    }

    label.ondrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this._state.dragCounter = 0;
      this._state.isDragging = false;
      label.classList.remove('common-ui__file_drop__active');

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (this._validateFile(file)) {
          this._state.file = file;
          this.showPreview();
          this.emit(this._eEventTypes.FILE_LOADED, file);
        }
        e.dataTransfer.clearData();
      }
    }
  }

  _validateFile(file) {
    let valid = true;
    if (!validateFileExtension(this._accept, file.type)) valid = false;
    if (getFileSizeMB(file) > this._maxFileSize) valid = false;
    return valid;
  }

  cleanup() {
    super.cleanup();
    this.interactiveElements[this._eElementsTypes.EET_INPUT].value = '';
    this._state = { ...this._initialState };
    this._revokeUrl && this._revokeUrl();
    this.showPlaceholder();
  }

  showPreview() {
    this._revokeUrl && this._revokeUrl();

    const url = URL.createObjectURL(this._state.file);
    this._revokeUrl = () => {
      URL.revokeObjectURL(url);
    }

    this.setInnerHTML(this._eElementsTypes.EET_PREVIEW, `<img src=${url} alt="" />`);
  }

  showPlaceholder() {
    this.setInnerHTML(this._eElementsTypes.EET_PREVIEW, '');
    this.interactiveElements[this._eElementsTypes.EET_PREVIEW].append(this.interactiveElements[this._eElementsTypes.EET_PLACEHOLDER]);
  }

  set maxFileSize(value) {
    this._maxFileSize = value;
  }

  set accept(value) {
    this._accept = value;
    this.interactiveElements[this._eElementsTypes.EET_INPUT].setAttribute('accept', value);
  }

  get file() {
    return this._state.file;
  }

  set scaleData(data) {
    super.scaleData = data;
  }

  _getPlaceholderMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLACEHOLDER]} class="common-ui__file_drop__placeholder">
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.409 34.409C35.2078 33.6102 36.4578 33.5376 37.3386 34.1912L37.591 34.409L46.591 43.409C47.4697 44.2877 47.4697 45.7123 46.591 46.591C45.7922 47.3898 44.5422 47.4624 43.6614 46.8088L43.409 46.591L34.409 37.591C33.5303 36.7123 33.5303 35.2877 34.409 34.409Z" fill="#F0BB00"/>
                <path d="M34.409 34.409C35.2877 33.5303 36.7123 33.5303 37.591 34.409C38.3898 35.2078 38.4624 36.4578 37.8088 37.3386L37.591 37.591L28.591 46.591C27.7123 47.4697 26.2877 47.4697 25.409 46.591C24.6102 45.7922 24.5376 44.5422 25.1912 43.6614L25.409 43.409L34.409 34.409Z" fill="#F0BB00"/>
                <path d="M36 33.75C37.1391 33.75 38.0805 34.5965 38.2295 35.6947L38.25 36V60C38.25 61.2426 37.2426 62.25 36 62.25C34.8609 62.25 33.9195 61.4035 33.7705 60.3053L33.75 60V36C33.75 34.7574 34.7574 33.75 36 33.75Z" fill="#F0BB00"/>
                <path d="M36 9.75C41.9321 9.75 47.5722 12.3243 51.4589 16.8057C54.7904 20.6469 56.5525 25.5653 56.4598 30.5892L56.445 30.96L56.5615 30.9796C63.0013 32.1485 67.9334 37.6453 68.2353 44.3477L68.25 45C68.25 52.6514 62.2196 58.8944 54.6523 59.2353L54 59.25H48C46.7574 59.25 45.75 58.2426 45.75 57C45.75 55.8609 46.5965 54.9195 47.6947 54.7705L48 54.75H54C59.3848 54.75 63.75 50.3848 63.75 45C63.75 39.6152 59.3848 35.25 54 35.25C52.7367 35.25 51.7435 34.2176 51.7496 32.9931L51.7724 32.6834L51.8026 32.4707C52.4571 27.8897 51.0913 23.25 48.0594 19.7541C45.0274 16.2582 40.6275 14.25 36 14.25C31.3725 14.25 26.9726 16.2582 23.9406 19.7541C21.087 23.0444 19.7094 27.3478 20.103 31.6633L20.2276 32.6834C20.4202 34.0384 19.3686 35.25 18 35.25C12.6152 35.25 8.25 39.6152 8.25 45C8.25 50.1991 12.3194 54.4477 17.4467 54.7346L18 54.75H24C25.2426 54.75 26.25 55.7574 26.25 57C26.25 58.1391 25.4035 59.0805 24.3053 59.2295L24 59.25H18C10.1299 59.25 3.75 52.8701 3.75 45C3.75 38.0044 8.79094 32.1862 15.4385 30.9796L15.552 30.96L15.5403 30.5903C15.4526 25.8445 17.0196 21.1938 20.0004 17.4557L20.5411 16.8057C24.4278 12.3243 30.0679 9.75 36 9.75Z" fill="#F0BB00"/>
              </svg>
              
              <div class="common-ui__file_drop__placeholder__text">
                <span class="common-ui__file_drop__placeholder__text_title" id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLACEHOLDER_TITLE]}></span>
                <span class="common-ui__file_drop__placeholder__text_description" id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLACEHOLDER_DESCRIPTION]}></span>
              </div>
            </div>`
  }


  _getMarkup() {
    return `<label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]} class='common-ui__file_drop'>
              <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_INPUT]} type='file' name='file' value='' accept=${this._accept} class='hidden' />
              <div class="common-ui__file_drop__preview" id=${this.interactiveElementsIds[this._eElementsTypes.EET_PREVIEW]}>${this._getPlaceholderMarkup()}</div>
            </label>`
  }
}
