export const validateFileExtension = (accept, fileExt) => {
  let valid = true;
  const canAcceptArray = accept.replace(/\s/g, '').split(',');

  cycle: for (let acceptExt of canAcceptArray) {
    const validExtParts = acceptExt.split('/');
    const fileExtParts = fileExt.split('/');

    for (let i = 0; i < validExtParts.length; i++) {
      if (validExtParts[i] === '*') {
        break cycle;
      }
      if (validExtParts[i] !== fileExtParts[i]) {
        valid = false;
        break cycle;
      }
    }
  }

  return valid;
};

export const getFileSizeMB = (file) => {
  return ((file.size/1024)/1024).toFixed(2);
}
