import BaseController from './baseSystemController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import ScaleManager from '../../../scaleManager/ScaleManager';
import { eInputTypes } from '../../../enums/inputTypes';

export default class ControllerGameSettings extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._defaultSizes = {
      H: {
        width: 2340,
        height: 1080,
      },
      V: {
        width: 1080,
        height: 2340
      }
    };

    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eInputTypes = {
      EIT_BACKGROUND_SOUND: 'background_sound',
      EIT_GAME_SOUND: 'game_sound',
      EIT_WIN_SOUND: 'win_sound',
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
      BACKGROUND_SOUND_CHANGE: this.getEventName(this._eInputTypes.EIT_BACKGROUND_SOUND, eInputTypes.EIT_INPUT),
      GAME_SOUND_CHANGE: this.getEventName(this._eInputTypes.EIT_GAME_SOUND, eInputTypes.EIT_INPUT),
      WIN_SOUND_CHANGE: this.getEventName(this._eInputTypes.EIT_WIN_SOUND, eInputTypes.EIT_INPUT),
    };
    this._eElementsTypes = {
      EET_OVERLAY: 'overlay',
      EET_MODAL_CONTAINER: 'modal_container',
      EET_TITLE: 'title',
      EET_BACKGROUND_SOUND_LABEL: 'background_sound_label',
      EET_GAME_SOUND_LABEL: 'game_sound_label',
      EET_WIN_SOUND_LABEL: 'win_sound_label',
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
      EET_BACKGROUND_SOUND_SWITCH: this._eInputTypes.EIT_BACKGROUND_SOUND,
      EET_GAME_SOUND_SWITCH: this._eInputTypes.EIT_GAME_SOUND,
      EET_WIN_SOUND_SWITCH: this._eInputTypes.EIT_WIN_SOUND,
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameSettings.title`,
      [this._eElementsTypes.EET_BACKGROUND_SOUND_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameSettings.background_sound_label`,
      [this._eElementsTypes.EET_GAME_SOUND_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameSettings.game_sound_label`,
      [this._eElementsTypes.EET_WIN_SOUND_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.gameSettings.win_sound_label`,
    };

    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: this.hide.bind(this),
    };

    this._isSpinBlocker = true;

    this.init(container);
    this.hide();
  }

  toggleBackgroundSound(enabled) {
    this._interactiveElements[this._eElementsTypes.EET_BACKGROUND_SOUND_SWITCH].checked = enabled;
  }

  toggleGameSound(enabled) {
    this._interactiveElements[this._eElementsTypes.EET_GAME_SOUND_SWITCH].checked = enabled;
  }

  toggleWinSound(enabled) {
    this._interactiveElements[this._eElementsTypes.EET_WIN_SOUND_SWITCH].checked = enabled;
  }


  _afterInit() {
    super._afterInit();
    this._overlay = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    this._modalContaier = this.interactiveElements[this._eElementsTypes.EET_MODAL_CONTAINER];
    this._overlay.onclick = (e) => {
      if (e.target === this._overlay) this.hide();
    };
  }

  _onDataUpdated() {
    super._onDataUpdated();
    // this.set(this._eElementsTypes.EET_MIN_BET_VALUE, formatCurrency(this._data.available_bets[0], this._data.currency));
    // this.set(this._eElementsTypes.EET_MAX_BET_VALUE, formatCurrency(this._data.available_bets[this._data.available_bets.length - 1], this._data.currency));
    // this.set(this._eElementsTypes.EET_MAX_WIN_VALUE, formatCurrency(this._data.maxWin, this._data.currency));
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    if (!this._mounted) return;

    const orientation = this._scaleData.orientation;
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    this._modalContaier.dataset.orientation = scaleData.isPortrait ? 'portrait' : 'landscape';
    this._modalContaier.dataset.deviceType = scaleData.isMobile ? 'mobile' : 'desktop';

    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this._overlay,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale + 2,
      defaultHeight: this._scaleData.innerHeight / uiScale,
    });
  }

  get dataSelector() {
    return (data) => data;
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OVERLAY]} class="system-ui-container__modal_wrapper system-ui-container__modal_wrapper__game_settings">
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_MODAL_CONTAINER]} class="system-ui-container__modal_container system-ui-container__modal__game_settings">
        <div class="system-ui-container__modal__game_settings__header">
          <div class="system-ui-container__modal__game_settings__header__title">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</span>
          </div>
          
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__modal__game_settings__header__close_button">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42 14L14 42M14 14L42 42" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <div class="system-ui-container__modal__game_settings__body">
          <div class="system-ui-container__modal__game_settings__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_BACKGROUND_SOUND_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_BACKGROUND_SOUND_LABEL])}</span>
            <div class="system-ui-container__modal__game_settings__body__row__toggle_box">
              <label class="common-ui__toggle_input">
              <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_BACKGROUND_SOUND_SWITCH]} type="checkbox" />
              </label>
            </div>
          </div>
          <div class="system-ui-container__modal__game_settings__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_GAME_SOUND_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_GAME_SOUND_LABEL])}</span>
            <div class="system-ui-container__modal__game_settings__body__row__toggle_box">
              <label class="common-ui__toggle_input">
              <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_GAME_SOUND_SWITCH]} type="checkbox" />
              </label>
            </div>
          </div>
          <div class="system-ui-container__modal__game_settings__body__row">
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_WIN_SOUND_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_WIN_SOUND_LABEL])}</span>
            <div class="system-ui-container__modal__game_settings__body__row__toggle_box">
              <label class="common-ui__toggle_input">
              <input id=${this.interactiveElementsIds[this._eElementsTypes.EET_WIN_SOUND_SWITCH]} type="checkbox" />
              </label>
            </div>
          </div>
        </div>
      </div>
      </div>`
  }
}
