import BaseSystemController from './baseSystemController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import { formatOrientation } from '../../../utils/orientation';
import ScaleManager from '../../../scaleManager/ScaleManager';
import ControllerSpinner from '../common/ControllerSpinner';
import ControllerRoundsList from './roundsHistory/ControllerRoundsList';
import ControllerRoundsResult from './roundsHistory/ControllerRoundsResult';
import eRoundsHistoryControllerTypes from './roundsHistory/eControllerTypes';

export default class ControllerRoundsHistory extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };
    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: () => this.hide(),
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eControllersClasses = {
      [this.controllerTypes.ERHCT_SPINNER]: ControllerSpinner,
      [this.controllerTypes.ERHCT_ROUNDS_LIST]: ControllerRoundsList,
      [this.controllerTypes.ERHCT_ROUND_RESULT]: ControllerRoundsResult,
    };
    this._eElementsTypes = {
      EET_SCALE_ROOT: 'scale_root',
      EET_CONTAINER_SPINNER: this.controllerTypes.ERHCT_SPINNER,
      EET_CONTAINER_ROUNDS_LIST: this.controllerTypes.ERHCT_ROUNDS_LIST,
      EET_CONTAINER_ROUND_RESULT: this.controllerTypes.ERHCT_ROUND_RESULT,
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
      EET_HEADER_TITLE: 'header_title',
    };
    this._localizations = {
      [this._eElementsTypes.EET_HEADER_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.roundsHistory.header_title`,
    };

    this._isSpinBlocker = true;

    this.init(container);
    this.hide();
  }

  onShow() {
    super.onShow();
    void this._requestRounds(1);
  }

  cleanup() {
    super.cleanup();
    this._abortRoundsRequest && this._abortRoundsRequest();
    this._abortResultRequest && this._abortResultRequest();
    window.OPWrapperService.model.update(prev => ({
      ...prev,
      rounds_history: {
        ...prev.rounds_history,
        rounds: {
          ...prev.rounds_history.rounds,
          list: [],
          hasMore: false
        }
      }
    }));
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
  }

  _addExtraControllersListeners() {
    super._addExtraControllersListeners();
    const controllerRoundsList = this.controllers[this.controllerTypes.ERHCT_ROUNDS_LIST];
    const events = this.events[this.controllerType];

    controllerRoundsList.on(events[this.controllerTypes.ERHCT_ROUNDS_LIST].LOAD_MORE, (page) => this._requestRounds(page));
    controllerRoundsList.on(events[this.controllerTypes.ERHCT_ROUNDS_LIST].SHOW_RESULT_CLICK, (round) => this._requestResult(round))
  }

  async _requestRounds(page) {
    const { abortRoundsRequest, promise } = window.OPWrapperService.serverManager.requestRoundsHistory(page);
    this._abortRoundsRequest = abortRoundsRequest;
    this._showSpinner();
    await promise;
    this._hideSpinner();
  }

  async _requestResult(round) {
    const id = round.provider_round;
    const region = round.region;
    const game_bundle = round.game_bundle;

    this._showSpinner();
    if (!this._data.results[region] || !this._data.results[region][id]) {
      const { abortResultRequest, promise } = window.OPWrapperService.serverManager.requestRoundResult({ id, region, game_bundle });
      this._abortResultRequest = abortResultRequest;
      await promise;
    }

    if (!this.isHidden) {
      await this.controllers[this.controllerTypes.ERHCT_ROUND_RESULT].open(round);
    }
    this._hideSpinner();
  }

  _showSpinner() {
    this.controllers[this.controllerTypes.ERHCT_SPINNER].show();
  }

  _hideSpinner() {
    this.controllers[this.controllerTypes.ERHCT_SPINNER].hide();
  }

  _onDataUpdated() {
    super._onDataUpdated();
    Object.values(this.controllers).forEach(controller => {
      if ('updateData' in controller) controller.updateData(this._data);
    })
  }

  get controllerTypes() {
    return eRoundsHistoryControllerTypes;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.orientation;
    const scaleBeforeScaleManagerInitialized = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    const uiScale = this._scaleData.scale || scaleBeforeScaleManagerInitialized;

    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    this.root.setAttribute('data-orientation', formatOrientation(orientation));
    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.root,
      relativeScale: uiScale,
    });
  }

  get dataSelector() {
    return (data) => data;
  }

  _getMarkup() {
    return `<div class="system-ui-container__modal_wrapper system-ui-container__modal_wrapper system-ui-container__modal_wrapper__rounds_history">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__modal_container system-ui-container__modal__rounds_history">
                <div class="system-ui-container__modal__rounds_history__header">
                  <div>
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_HEADER_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_HEADER_TITLE])}</span>
                  </div>
            
                  <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__close_button">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M20.9989 51.3331H34.9989C46.6655 51.3331 51.3322 46.6664 51.3322 34.9997V20.9997C51.3322 9.33305 46.6655 4.66638 34.9989 4.66638H20.9989C9.33219 4.66638 4.66553 9.33305 4.66553 20.9997V34.9997C4.66553 46.6664 9.33219 51.3331 20.9989 51.3331Z"
                          fill="#17142A" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21.3965 34.6032L34.6032 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M34.6032 34.6032L21.3965 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                  </button>
                </div>
            
                <div class="system-ui-container__modal__rounds_history__body">
                  <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_SPINNER]} class="system-ui-container__modal__rounds_history__container_spinner"></div>
                
                  <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROUNDS_LIST]} class="system-ui-container__modal__rounds_history__body__rounds"></div>
                  
                  <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROUND_RESULT]}></div>
                </div>
              </div>
            </div>`
  }
}
