import UserAgentDetector from '../utils/UserAgentDetector';

const DELAY_TILL_VIRTUAL_KEYBOARD_OPEN = 1000;
const VIRTUAL_KEYBOARD_MIN_HEIGHT = 150;

export default new class VirtualKeyboardManager {
  constructor() {
    this._isInputInFocus = false;
    this._lastFocusTimestamp = null;
    this._viewPortHeightOnFocusIn = null;
    this._isVirtualKeyboardAffectsLayout = false;

    this._onResize = this._onResize.bind(this);
    this._onFocusIn = this._onFocusIn.bind(this);
    this._onFocusOut = this._onFocusOut.bind(this);

    if (UserAgentDetector.isMobile.any) {
      if ('virtualKeyboard' in navigator) {
        console.log('virtualKeyboard api is available');
        navigator.virtualKeyboard.overlaysContent = true;
      } else {
        console.log('virtualKeyboard api is not available');
      }

      this._createInteractiveWidgetMeta();
      this._addListeners();
    }
  }

  _addListeners() {
    visualViewport.addEventListener('resize', this._onResize);

    document.addEventListener('fullscreenchange', this._onResize);
    /* Firefox */
    document.addEventListener("mozfullscreenchange", this._onResize);
    /* Chrome, Safari and Opera */
    document.addEventListener("webkitfullscreenchange", this._onResize);
    /* IE / Edge */
    document.addEventListener("msfullscreenchange", this._onResize);

    // listen to focus events
    window.addEventListener('focusin', this._onFocusIn);
    window.addEventListener('focusout', this._onFocusOut);
  }

  _onResize() {
    if (window._debugVirtualKeyBoard) {
      console.log('in focus: ', this._isInputInFocus);
      console.log('initial viewport height on focus in: ', this._viewPortHeightOnFocusIn);
      console.log('current viewport height: ', visualViewport.height);
      console.log('timestamp diff: ', Date.now() - this._lastFocusTimestamp);
    }

    this._isVirtualKeyboardAffectsLayout = this._isInputInFocus && Date.now() - this._lastFocusTimestamp < DELAY_TILL_VIRTUAL_KEYBOARD_OPEN && this._viewPortHeightOnFocusIn - visualViewport.height >= VIRTUAL_KEYBOARD_MIN_HEIGHT;
  }

  _onFocusIn(e) {
    if ((e.target.nodeName === 'INPUT' && ['text', 'number'].includes(e.target.type)) || e.target.nodeName === 'TEXTAREA') {
      this._lastFocusTimestamp = Date.now();
      this._isInputInFocus = true;
      this._viewPortHeightOnFocusIn = visualViewport.height;
    }
  }

  _onFocusOut(e) {
    if ((e.target.nodeName === 'INPUT' && ['text', 'number'].includes(e.target.type)) || e.target.nodeName === 'TEXTAREA') {
      this._isInputInFocus = false;
    }
  }

  _createInteractiveWidgetMeta() {
    const tag = document.createElement('meta');
    tag.setAttribute('name', 'interactive-widget');
    tag.setAttribute('content', 'overlays-content');
    document.head.appendChild(tag);
  }

  get isVirtualKeyboardAffectsLayout() {
    return this._isVirtualKeyboardAffectsLayout;
  }
}
