import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import eEventTypes from '../enums/eEventTypes';

export default class StopRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_REELS_STOPPED, this.onReelsStopped, this);
        GlobalDispatcher.add(eEventTypes.EET_EXTRA_REELS_STOPPED, this.onExtraReelsStopped, this);
    }

    onReelsStopped() {
        if (this.active) {
            this._reelsStoped = true;
            if (this._extraReelsStoped) {
                this.complete();
            }
        }
    }

    onExtraReelsStopped() {
        if (this.active) {
            this._extraReelsStoped = true;
            if (this._reelsStoped) {
                this.complete();
            }
        }
    }

    onEnd() {
        super.onEnd();
        this._reelsStoped = false;
        this._extraReelsStoped = false;
    }
}
