export default {
  CANVAS_WIDTH_LANDSCAPE: 2340,
  CANVAS_HEIGHT_LANDSCAPE: 1080,

  CANVAS_WIDTH_PORTRAIT: 2340,
  CANVAS_HEIGHT_PORTRAIT: 1080,

  SAFE_WIDTH_LANDSCAPE: 1900,
  SAFE_HEIGHT_LANDSCAPE: 1080,

  SAFE_WIDTH_PORTRAIT: 1040,
  SAFE_HEIGHT_PORTRAIT: 1800,

  BIND_TO_TOP: false,
}
