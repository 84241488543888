import FlashLib from 'flashlib_onlyplay';

function centring () {
  this.centringX();
  this.centringY();
}

function centringX () {
  this.pivot.x = this.displayData.width / 2;
  this.x = this.displayData.x + this.displayData.width / 2;
}

function centringY () {
  this.pivot.y = this.displayData.height / 2;
  this.y = this.displayData.y + this.displayData.height / 2;
}

function anchorDefault () {
  this.anchorDefaultX();
  this.anchorDefaultY();
}

function anchorDefaultX () {
  this.pivot.x = 0;
  this.x = this.displayData.x;
}

function anchorDefaultY () {
  this.pivot.y = 0;
  this.y = this.displayData.y;
}

class BitmapTextWithAlign extends PIXI.BitmapText {
  constructor(...params) {
    super(...params);
  }
  updateText () {
    switch(this.align) {
      case 'center':
        this._anchor._x = 0.5
        break;
      case 'right':
        this._anchor._x = 1
        break;
      default:
        this._anchor._x = 0
        break;
    }
    super.updateText();
  }
}

function toBmText (chars) {
  const uniqueId = Date.now().toString();
  PIXI.BitmapFont.from(uniqueId, { ...this.style}, chars && { chars });

  const bmText = new BitmapTextWithAlign(this.text, { fontName: uniqueId, align: this.style.align, fontSize: this.style.fontSize, letterSpacing: this.style.letterSpacing, maxWidth: this.style.maxWidth});

  switch (bmText.align) {
    case 'left':
      bmText.transform.position.x = this.displayData.x;
      break
    case 'center':
      bmText.transform.position.x = this.displayData.x +  this.displayData.width / 2;
      break
    case 'right':
      bmText.transform.position.x = this.displayData.x +  this.displayData.width;
      break;
    default:
      bmText.transform.position.x = this.displayData.x;
  }
  bmText.position.y = this.position.y;

  const index = this.parent.children.indexOf(this)
  this.parent.addChildAt(bmText, index)
  this.destroy();

  return bmText;
}

FlashLib.MovieClip.prototype.centring = centring;

FlashLib.MovieClip.prototype.centringX = centringX;

FlashLib.MovieClip.prototype.centringY = centringY;

FlashLib.MovieClip.prototype.anchorDefault = anchorDefault;

FlashLib.MovieClip.prototype.anchorDefaultX = anchorDefaultX;

FlashLib.MovieClip.prototype.anchorDefaultY = anchorDefaultY;

FlashLib.TextField.prototype.centring = centring;

FlashLib.TextField.prototype.centringX = centringX;

FlashLib.TextField.prototype.centringY = centringY;

FlashLib.TextField.prototype.anchorDefault = anchorDefault;

FlashLib.TextField.prototype.anchorDefaultX = anchorDefaultX;

FlashLib.TextField.prototype.anchorDefaultY = anchorDefaultY;

FlashLib.TextField.prototype.toBmText = toBmText;



