import BaseController from './basePlayerController';

export default class ControllerTurboPlay extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_TURBO_SPIN: 'turbo_spin_button',
    };
    this._eEventTypes = {
      TURBO_SPIN_CLICK: this.getEventName(this._eButtonsTypes.EBT_TURBO_SPIN),
    };
    this._eElementsTypes = {
      EET_TURBO_SPIN_BUTTON: this._eButtonsTypes.EBT_TURBO_SPIN,
    };

    this.init(container);
  }

  toggleActive(value) {
    if (value) {
      this.interactiveElements[this._eElementsTypes.EET_TURBO_SPIN_BUTTON].classList.add('toggled_on');
    } else {
      this.interactiveElements[this._eElementsTypes.EET_TURBO_SPIN_BUTTON].classList.remove('toggled_on');
    }
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_TURBO_SPIN_BUTTON]}  ${this._container.dataset.orientation === 'landscape' ? '' : 'data-color="primary"'}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1447 36.6562L41.9616 19.3416C42.9814 17.8502 45.0171 17.468 46.5085 18.4878C47.3766 19.0815 47.9064 20.0563 47.9328 21.1507L47.9305 30.2973L55.4412 30.2979C57.1193 30.2595 58.5277 31.5084 58.7215 33.142L58.7434 33.4526C58.7586 34.1183 58.5677 34.7723 58.1889 35.3369L46.3673 52.6586C45.3475 54.1499 43.3118 54.5322 41.8204 53.5123C40.9523 52.9187 40.4225 51.9438 40.3961 50.8494L40.3938 41.6957L32.8924 41.6953C31.2142 41.7336 29.8059 40.4847 29.6121 38.8511L29.5902 38.5405C29.575 37.8749 29.7659 37.2209 30.1447 36.6562ZM44.4328 32.0474V21.9253L33.3285 38.1957L42.1461 38.1957C43.0321 38.1957 43.7643 38.8541 43.8801 39.7083L43.8961 39.9457L43.8938 50.0723L55.0005 33.7973L46.1828 33.7974C45.2968 33.7974 44.5646 33.139 44.4488 32.2849L44.4328 32.0474Z" fill="white"/>
            <path d="M29 48.25C29.9665 48.25 30.75 49.0335 30.75 50C30.75 50.886 30.0916 51.6181 29.2375 51.734L29 51.75H16.75C15.7835 51.75 15 50.9665 15 50C15 49.114 15.6584 48.3819 16.5125 48.266L16.75 48.25H29Z" fill="white"/>
            <path d="M22.8867 35.4165C23.8532 35.4165 24.6367 36.2 24.6367 37.1665C24.6367 38.0525 23.9784 38.7846 23.1242 38.9005L22.8867 38.9165H12.6667C11.7002 38.9165 10.9167 38.133 10.9167 37.1665C10.9167 36.2805 11.5751 35.5484 12.4293 35.4325L12.6667 35.4165H22.8867Z" fill="white"/>
            <path d="M29 22.5835C29.9665 22.5835 30.75 23.367 30.75 24.3335C30.75 25.2195 30.0916 25.9516 29.2375 26.0675L29 26.0835H16.75C15.7835 26.0835 15 25.3 15 24.3335C15 23.4475 15.6584 22.7154 16.5125 22.5995L16.75 22.5835H29Z" fill="white"/>
          </svg>
        </button>`
  }
}
