import Event from 'Engine/utils/event';

export default class ControllerAutoPlay {
  constructor() {
    this._availableCounts = [10, 20, 50, 100, 200, 500, 1000, Infinity];
    this._activeCountIndex = this._availableCounts.length - 1;

    this._active = false;
    this._stopByBonusGameWin  = false;
    this._stopByWinAmount  = false;
    this._stopByBalanceIncreaseAmount  = false;
    this._stopByBalanceDecreaseAmount  = false;
    this._winAmountForStop  = 0;
    this._balanceIncreaseAmountForStop  = 0;
    this._balanceDecreaseAmountForStop  = 0;
    this._quickPlay  = false;
    this._count = Infinity;

    this.onChange = new Event();

    this._reset();


    OPUtility.ControllerStatistic.onWin.add(this.onWin.bind(this));
    OPUtility.ControllerStatistic.onBet.add(this.onBet.bind(this));
    OPUtility.ControllerStatistic.onBonusGameWin.add(this.onBonusGameWin.bind(this));
  }

  onWin(winAmount) {
    if (!winAmount) return;
    this._lastWin = winAmount;
    this._currentTotalWinAmount += winAmount;
    this._tryStop();
  }

  onBet(betAmount) {
    if (betAmount) this._currentTotalBetsAmount += betAmount;
    this.remainingCount--;
    this._tryStop();
  }

  onBonusGameWin() {
    this._isBonusGameWin = true;
    this._tryStop();
  }

  _tryStop() {
    const balanceChange = this._currentTotalWinAmount - this._currentTotalBetsAmount;
    if (
      (this._stopByBonusGameWin && this._isBonusGameWin)
      || (this._stopByWinAmount && this._winAmountForStop > 0 && this._lastWin >= this._winAmountForStop)
      || (this._stopByBalanceIncreaseAmount && this._balanceIncreaseAmountForStop > 0 && balanceChange >= this._balanceIncreaseAmountForStop)
      || (this._stopByBalanceDecreaseAmount && this._balanceDecreaseAmountForStop > 0 && -balanceChange >= this._balanceDecreaseAmountForStop)
      || this.remainingCount <= 0
    ) {
      this.active = false;
    }
  }

  _reset() {
    this.remainingCount = this._count;
    this._currentTotalWinAmount = 0;
    this._currentTotalBetsAmount = 0;
    this._lastWin = 0;
    this._isBonusGameWin = false;
  }

  set active(value) {
    this._active = value;
    if (!value) this._reset();
    this.onChange.call();
  }

  get active() {
    return this._active;
  }
  set stopByBonusGameWin(value) {
    this._stopByBonusGameWin = value;
    this.onChange.call();
  }

  get stopByBonusGameWin() {
    return this._stopByBonusGameWin;
  }
  set stopByWinAmount(value) {
    this._stopByWinAmount = value;
    this.onChange.call();
  }

  get stopByWinAmount() {
    return this._stopByWinAmount;
  }
  set stopByBalanceIncreaseAmount(value) {
    this._stopByBalanceIncreaseAmount = value;
    this.onChange.call();
  }

  get stopByBalanceIncreaseAmount() {
    return this._stopByBalanceIncreaseAmount;
  }
  set stopByBalanceDecreaseAmount(value) {
    this._stopByBalanceDecreaseAmount = value;
    this.onChange.call();
  }

  get stopByBalanceDecreaseAmount() {
    return this._stopByBalanceDecreaseAmount;
  }
  set winAmountForStop(value) {
    console.log('winAmountForStop', value);
    this._winAmountForStop = value;
    this.onChange.call();
  }

  get winAmountForStop() {
    return this._winAmountForStop;
  }
  set balanceIncreaseAmountForStop(value) {
    this._balanceIncreaseAmountForStop = value;
    this.onChange.call();
  }

  get balanceIncreaseAmountForStop() {
    return this._balanceIncreaseAmountForStop;
  }
  set balanceDecreaseAmountForStop(value) {
    this._balanceDecreaseAmountForStop = value;
    this.onChange.call();
  }

  get balanceDecreaseAmountForStop() {
    return this._balanceDecreaseAmountForStop;
  }
  set quickPlay(value) {
    this._quickPlay = value;
    this.onChange.call();
  }

  get quickPlay() {
    return this._quickPlay;
  }

  set count(value) {
    this._count = value;
    this._reset();
    this.onChange.call();
  }

  get count() {
    return this._count;
  }

  set availableCounts(value) {
    this._availableCounts = value;
    this._count = this._availableCounts[this._availableCounts.length - 1];
    this._reset();
    this.onChange.call();
  }

  get availableCounts() {
    return this._availableCounts;
  }

  set activeCountIndex(value) {
    this._activeCountIndex = value;
    this._count = this._availableCounts[this._activeCountIndex];
    this._reset();
    this.onChange.call();
  }

  get activeCountIndex() {
    return this._activeCountIndex;
  }
  set remainingCount(value) {
    this._remainingCount = value;
    this.onChange.call();
  }

  get remainingCount() {
    return this._remainingCount;
  }
}
