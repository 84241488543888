import BaseController from './basePlayerController';

export default class ControllerInfo extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_INFO: 'info_button',
    };
    this._eEventTypes =  {
      INFO_CLICK: this.getEventName(this._eButtonsTypes.EBT_INFO),
    };
    this._eElementsTypes = {
      EET_INFO_BUTTON: this._eButtonsTypes.EBT_INFO,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_INFO_BUTTON]} data-role="info">
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 36C10 21.6406 21.6406 10 36 10C50.3594 10 62 21.6406 62 36C62 50.3594 50.3594 62 36 62C21.6406 62 10 50.3594 10 36ZM58 36C58 23.8497 48.1503 14 36 14C23.8497 14 14 23.8497 14 36C14 48.1503 23.8497 58 36 58C48.1503 58 58 48.1503 58 36Z" fill="white"/>
              <path d="M39.493 43.3333C40.5976 43.3333 41.493 44.2287 41.493 45.3333C41.493 46.3458 40.7406 47.1826 39.7644 47.315L39.493 47.3333H33.333C32.2284 47.3333 31.333 46.4378 31.333 45.3333C31.333 44.3207 32.0854 43.4839 33.0616 43.3515L33.333 43.3333H39.493Z" fill="white"/>
              <path d="M36.4241 32C37.4366 32 38.2734 32.7524 38.4058 33.7286L38.4241 34V45.3333C38.4241 46.4379 37.5287 47.3333 36.4241 47.3333C35.4116 47.3333 34.5748 46.5809 34.4423 45.6047L34.4241 45.3333V36H33.3574C32.3449 36 31.5081 35.2476 31.3757 34.2714L31.3574 34C31.3574 32.9875 32.1098 32.1507 33.086 32.0183L33.3574 32H36.4241Z" fill="white"/>
              <path d="M35.6003 23.3259C36.2446 23.3259 36.8178 23.6306 37.1835 24.1038L37.3043 24.2852L37.4891 24.4093C37.8946 24.7228 38.1764 25.1887 38.2487 25.7212L38.2669 25.9926C38.2669 27.4654 37.073 28.6593 35.6003 28.6593C34.1275 28.6593 32.9336 27.4654 32.9336 25.9926C32.9336 24.5198 34.1275 23.3259 35.6003 23.3259Z" fill="white"/>
              <path d="M35.5996 23.3259C37.0724 23.3259 38.2663 24.5198 38.2663 25.9926C38.2663 27.0972 37.3708 27.9926 36.2663 27.9926C35.6219 27.9926 35.0488 27.6879 34.683 27.2147L34.5596 27.0292L34.3775 26.9092C33.9719 26.5957 33.6901 26.1298 33.6179 25.5973L33.5996 25.3259C33.5996 24.2214 34.495 23.3259 35.5996 23.3259Z" fill="white"/>
            </svg>  
          </button>`
  }
}
