import { getUrlParam } from '../utils/url';
import environmentTypes from '../enums/environmentTypes';

export default class UrlParser {
  constructor() {
    this._urlParams = this._parseUrlParams();
    this._environment = this._parseEnvironment(this._urlParams.api);
  }

  getParam(key) {
    return this._urlParams[key];
  }

  _parseUrlParams() {
    let result = {};

    try {
      let params = getUrlParam('params');
      if (params) {
        result = JSON.parse(window.atob(params));
      } else {
        result.api = getUrlParam('api');
        result.sid = getUrlParam('sid');
        result.gid = getUrlParam('gid');
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  _parseEnvironment(api) {
    let env = '';
    if (api) {
      const match = api.match(new RegExp(`\.(${environmentTypes.DEV}|${environmentTypes.STAGE})\.`));
      env = match ? match[0].replace(/\./g, '') : environmentTypes.PRODUCTION;
    }

    return env;
  }

  get params() {
    return this._urlParams;
  }

  get environment() {
    return this._environment;
  }
}
