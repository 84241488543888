import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerAutoPlayCount extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.auto_spin_count_label`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this.init(container);
    this.onChangeCount();
    window.OPWrapperService.autoPlay.onChange.add(this.onChangeCount.bind(this));
    this.hide();
  }

  onChangeCount() {
    this.setValue(window.OPWrapperService.autoPlay.remainingCount === Infinity ? '∞' : window.OPWrapperService.autoPlay.remainingCount);
  }

  setValue(value) {
    this.set(this._eElementsTypes.EET_VALUE, value);
  }

  _getMarkup() {
    return `<div class="ui-container__title-desc-col">
          <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
            ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
          </label>
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
            99
          </span>
        </div>`
  }
}
