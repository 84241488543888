import BaseController from './basePlayerController';

export default class ControllerSound extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_SOUND_MUTE: 'sound_mute_button',
      EBT_SOUND_UNMUTE: 'sound_unmute_button',
    };
    this._eEventTypes = {
      SOUND_MUTE_CLICK: this.getEventName(this._eButtonsTypes.EBT_SOUND_MUTE),
      SOUND_UNMUTE_CLICK: this.getEventName(this._eButtonsTypes.EBT_SOUND_UNMUTE),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_SOUND_MUTE]: this.onMuteClick.bind(this),
      [this._eButtonsTypes.EBT_SOUND_UNMUTE]: this.onUnmuteClick.bind(this),
    };
    this._eElementsTypes = {
      EET_SOUND_MUTE_BUTTON: this._eButtonsTypes.EBT_SOUND_MUTE,
      EET_SOUND_UNMUTE_BUTTON: this._eButtonsTypes.EBT_SOUND_UNMUTE,
    };

    this.init(container);

    this._soundMuted = false;
    this._showMuteButton()
  }

  setMuted(value) {
    this._soundMuted = value;
    if (this._soundMuted) {
      this._showUnmuteButton();
    } else {
      this._showMuteButton();
    }
  }

  emit(event, data) {
    super.emit(event, this._soundMuted);
  }

  onMuteClick() {
    this._soundMuted = true;
    this._showUnmuteButton();
  }

  onUnmuteClick() {
    this._soundMuted = false;
    this._showMuteButton();
  }

  _showMuteButton() {
    this.interactiveElements[this._eElementsTypes.EET_SOUND_MUTE_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON].classList.add('hidden');
  }

  _showUnmuteButton() {
    this.interactiveElements[this._eElementsTypes.EET_SOUND_MUTE_BUTTON].classList.add('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON].classList.remove('hidden');
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SOUND_MUTE_BUTTON]}>
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.984 12.4853C27.9191 9.99722 32.3683 11.936 32.6522 15.6666L32.6665 16.0456V47.9522C32.6665 51.7994 28.3087 53.9434 25.2824 51.7471L24.9844 51.5129L14.3737 42.5236C13.531 41.8096 13.4266 40.5475 14.1406 39.7048C14.7897 38.9386 15.8916 38.7827 16.7198 39.2971L16.9594 39.4716L27.5716 48.4623C27.9548 48.7876 28.5219 48.5806 28.6432 48.1314L28.6665 47.9522V16.0456C28.6665 15.5397 28.1429 15.2407 27.7219 15.4388L27.57 15.5369L16.9594 24.5262C16.1166 25.2402 14.8546 25.1358 14.1406 24.293C13.4915 23.5269 13.5187 22.4142 14.1623 21.6818L14.3737 21.4742L24.984 12.4853Z" fill="white"/>
            <path d="M15.6665 21C16.7711 21 17.6665 21.8954 17.6665 23C17.6665 24.0125 16.9141 24.8493 15.9379 24.9817L15.6665 25H9.33317C9.01823 25 8.75339 25.2198 8.68415 25.514L8.6665 25.6667V38.3333C8.6665 38.6483 8.88635 38.9131 9.18055 38.9824L9.33317 39H15.6665C16.7711 39 17.6665 39.8954 17.6665 41C17.6665 42.0125 16.9141 42.8493 15.9379 42.9817L15.6665 43H9.33317C6.88543 43 4.87673 41.1137 4.68198 38.716L4.6665 38.3333V25.6667C4.6665 23.2189 6.55282 21.2102 8.95053 21.0155L9.33317 21H15.6665Z" fill="white"/>
            <path d="M48 19.3335C49.0125 19.3335 49.8493 20.0859 49.9817 21.0621L50 21.3335V42.6668C50 43.7714 49.1046 44.6668 48 44.6668C46.9875 44.6668 46.1507 43.9144 46.0183 42.9382L46 42.6668V21.3335C46 20.2289 46.8954 19.3335 48 19.3335Z" fill="white"/>
            <path d="M40 24.6665C41.0125 24.6665 41.8493 25.4189 41.9817 26.3951L42 26.6665V37.3332C42 38.4377 41.1046 39.3332 40 39.3332C38.9875 39.3332 38.1507 38.5808 38.0183 37.6046L38 37.3332V26.6665C38 25.5619 38.8954 24.6665 40 24.6665Z" fill="white"/>
            <path d="M56 11.3335C57.0125 11.3335 57.8493 12.0859 57.9817 13.0621L58 13.3335V50.6668C58 51.7714 57.1046 52.6668 56 52.6668C54.9875 52.6668 54.1507 51.9144 54.0183 50.9382L54 50.6668V13.3335C54 12.2289 54.8954 11.3335 56 11.3335Z" fill="white"/>
          </svg>  
        </button>
        <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SOUND_UNMUTE_BUTTON]}>
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.984 12.4853C27.9191 9.99722 32.3683 11.936 32.6522 15.6666L32.6665 16.0456V47.9522C32.6665 51.7994 28.3087 53.9434 25.2824 51.7471L24.9844 51.5129L14.3737 42.5236C13.531 41.8096 13.4266 40.5475 14.1406 39.7048C14.7897 38.9386 15.8916 38.7827 16.7198 39.2971L16.9594 39.4716L27.5716 48.4623C27.9548 48.7876 28.5219 48.5806 28.6432 48.1314L28.6665 47.9522V16.0456C28.6665 15.5397 28.1429 15.2407 27.7219 15.4388L27.57 15.5369L16.9594 24.5262C16.1166 25.2402 14.8546 25.1358 14.1406 24.293C13.4915 23.5269 13.5187 22.4142 14.1623 21.6818L14.3737 21.4742L24.984 12.4853Z" fill="white"/>
            <path d="M15.6665 21C16.7711 21 17.6665 21.8954 17.6665 23C17.6665 24.0125 16.9141 24.8493 15.9379 24.9817L15.6665 25H9.33317C9.01823 25 8.75339 25.2198 8.68415 25.514L8.6665 25.6667V38.3333C8.6665 38.6483 8.88635 38.9131 9.18055 38.9824L9.33317 39H15.6665C16.7711 39 17.6665 39.8954 17.6665 41C17.6665 42.0125 16.9141 42.8493 15.9379 42.9817L15.6665 43H9.33317C6.88543 43 4.87673 41.1137 4.68198 38.716L4.6665 38.3333V25.6667C4.6665 23.2189 6.55282 21.2102 8.95053 21.0155L9.33317 21H15.6665Z" fill="white"/>
            <path d="M53.4657 24.4256C54.2467 23.6446 55.513 23.6446 56.2941 24.4256C57.0041 25.1357 57.0687 26.2468 56.4877 27.0297L56.2941 27.2541L44.2941 39.2541C43.513 40.0351 42.2467 40.0351 41.4657 39.2541C40.7556 38.544 40.6911 37.4329 41.272 36.6499L41.4657 36.4256L53.4657 24.4256Z" fill="white"/>
            <path d="M41.4657 24.4256C42.1757 23.7156 43.2868 23.651 44.0698 24.232L44.2941 24.4256L56.2941 36.4256C57.0751 37.2067 57.0751 38.473 56.2941 39.2541C55.5841 39.9641 54.4729 40.0287 53.69 39.4477L53.4657 39.2541L41.4657 27.2541C40.6846 26.473 40.6846 25.2067 41.4657 24.4256Z" fill="white"/>
          </svg>
        </button>
`
  }
}
