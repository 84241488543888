import eStateTypes from './eStateTypes';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';

export default {
  EET_ERROR_MANAGER__SHOW_ERROR: 'errorManager:showError', //Engine
  EET_SERVER_MANAGER__UPDATE_BALANCE: 'dataProvider:updateBalance',
  EET_GAME__STARTED: 'game:gameStarted', //Engine
  EET_PRELOADER_CONTROLLER__UPDATE_PROGRESS: 'preloaderController:progress', //Engine
  EET_BET_CHANGED: 'model:betChanged',
  EET_DATA_UPDATED: 'model:getServerData',

  EET_SPIN_CLICK: 'ui:spinClick',
  EET_PAYTABLE__CLICK: 'ui:paytableClick',
  EET_PAYTABLE__CHANGE_PAGE: 'ui:paytableChangePage',
  EET_PAYTABLE__BACK_CLICK: 'ui:paytableBackClick',

  EET_SETTINGS__CLICK: 'ui:settingsClick', //Engine
  EET_SETTINGS__SOUND_MUTE: 'gameSettings:soundMute', //Engine
  EET_SETTINGS__MUSIC_MUTE: 'gameSettings:musicMute', //Engine


  EET_REELS_STOPPED: 'reelsContainer:reelsStopped',
  EET_FOURTH_REEL_STOPPED: 'reelsContainer:fourthReelStopped',
  EET_EXTRA_REELS_STOPPED: 'ExtraReelsContainer:reelsStopped',

  EET_STATE_MANAGER__STATE_CHANGED: 'stateManager.stateChanged', //Engine
  EET_WINNINGS_CONTROLLER__ALL_WINNINGS_COMPLETE: 'winningsController:allWinningsComplete', //Engine
  EET_MODEL__LINES_CHANGED: 'model:linesChanged', //Engine

  EET_WINNINGS_CONTROLLER__SLOW_WIN: 'winningsController:slowWin', //Engine
  EET_START_SCREEN__SHOW: 'startScreen:show',
  EET_START_SCREEN__HIDE: 'startScreen:hide',
  EET_BIG_WIN__SKIP: 'bigWinContainer:stopState', //Engine
  EET_BIG_WIN__SKIP_COMPLETED: 'bigWinContainer:nextState', //Engine

  EET_INIT_STATE__END: `stateEnd:${eStateTypes.EST_INIT}`, //Engine
  EET_IDLE_STATE__START: `stateStart:${eStateTypes.EST_IDLE}`,
  EET_ROLLING_STATE__START: `stateStart:${eStateTypes.EST_REQUEST_ROLLING}`,
  EET_SHOW_WIN_STATE__START: `stateStart:${eStateTypes.EST_SHOW_WIN}`,
  EET_STOP_WIN_STATE__START: `stateStart:${eStateTypes.EST_STOP_WIN}`,
  EET_STOP_WIN_STATE__END: `stateEnd:${eStateTypes.EST_STOP_WIN}`,
  EET_JACKPOT_GAME_IN__START: `stateStart:${eStateTypes.EST_JACKPOT_GAME_IN}`,
  EET_JACKPOT_GAME_OUT__START: `stateStart:${eStateTypes.EST_JACKPOT_GAME_OUT}`,
  ...eBaseEventTypes
}
