const uiColors = {
  // stop: {
  //   default: '#359500',
  //   hover: '#2B8B0A',
  //   pressed: '#177700',
  //   disabled: 'rgba(0, 0, 0, 0.3)',
  // },
  spin: {
    disabled: '#7BDB00',
  },
  // bets_table_primary: {
  //   default: 'rgba(0,0,0,0.5)'
  // }
};


const uiConfig = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
      bottom_panel: { top: 0, left: 0 },
    },
  },
  H_mobile: {
    colors: uiColors,
    offsets: {
      left_panel: { top: -30, left: 0 },
      right_panel: { top: -30, left: -11 },
      bottom_panel: { top: -30, left: 0 },
    },
  },
  V: {
    colors: uiColors,
    offsets: {
      top: -30,
      left: 0,
    },
  }
}

export {
  uiConfig,
}
