import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';

export default class BackgroundContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.initAnimation();
        // this.active = false;
        this.addListeners();
    }

    addListeners() {
        GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_IN__START, this.hideTemporarily, this);
        GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_OUT__START, this.tryShow, this);
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
    }

    onResize (data) {
        if (data.isLandscape) {
            this.goToFrame(2)
            if (!this.isJackpotGame) this.animation.visible = true;
            this.isPortrait = false;
        } else if (data.isPortrait) {
            this.goToFrame(1)
            this.animation.visible = false;
            this.isPortrait = true;
        }
    }

    initAnimation() {
        this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BACKGROUND);
        this.animation.state.setAnimation(0, eAnimationNames.EAN_BACKGROUND__LANDSCAPE, true);
        this.getChildByName('animationContainer').addChild(this.animation);
    }

    hideTemporarily() {
        setTimeout(()=>{
            this.isJackpotGame = true;
            this.animation.visible = false;
        }, 2000);
    }

    tryShow() {
        this.isJackpotGame = false;
        if (!this.isPortrait) this.animation.visible = true;
    }
}
