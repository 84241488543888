import FlashLib from 'flashlib_onlyplay';
import eAnimationNames from '../../enums/eAnimationNames';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import { gsap } from 'gsap';
import eEventTypes from '../../enums/eEventTypes';

export default class SymbolFire extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.visible = false;

    this.initAnimation();
    this.addListeners();
  }
  initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.SYMBOL_FRAME_AND_FIRE);
    this.animation.visible = false;
    this.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_IN__START, this.hideTemporarily, this);
    GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_OUT__START, this.tryShow, this);
  }

  start() {
    if (this.animation.visible) return;
    this.alpha = 0;
    this.visible = true;
    gsap.to(this, { alpha: 1, duration: 0.4 })
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eAnimationNames.EAN_SYMBOL_FIRE, true);
  }

  stop() {
    if (!this.visible) return;
    this.visible = false;
    this.animation.visible = false;
  }

  hideTemporarily() {
    this.isShowAfterTemporarilyHide = this.animation.visible;
    setTimeout(this.stop.bind(this), 2000)
  }

  tryShow() {
    this.isShowAfterTemporarilyHide && this.start();
  }
}
