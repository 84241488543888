import LocalizationManager from '../i18n/LocalizationManager';
import './rules.css';
import UserAgentDetector from '../utils/UserAgentDetector';
import { OP_WRAPPER_LOCALIZATION_PREFIX, SOCIAL_LANGUAGE_KEY } from '../utils/constants';
import { getUrlParam } from '../utils/url';
import ControllerSpinner from '../ui/controllers/common/ControllerSpinner';
import Utility from '../utility';

export default class ControllerRules {
  constructor(container, config) {
    this.config = config;
    this.container = container;

    const env = Utility.urlParser.environment;
    let domainPrefix = '';
    let path = '';

    if (env !== Utility.enironmentTypes.PRODUCTION) {
      domainPrefix = `${env}-`;
      path = `/${env}`;
    }

    this.basePdfUrl = 'https://res.onlyplay.net/rules';
    this.baseHtmlUrl = `https://${domainPrefix}res.onlyplay.net/rulesV2${path}`;
    this.baseFrameUrl = 'https://pdf-viewer.onlyplay.net';
    this.fallbackLanguage = 'en';
    this.isOpened = false;

    this._render();
    this.init();

    window.OPWrapperService.sideBar.addButton({
      type: 'info',
      styles: ['rules'],
      onClick: () => this.isOpened ? this.close() : this.show()
    });

    LocalizationManager.addLocalizationChangedCallback(this.update.bind(this));
    this._stopSpin = this._stopSpin.bind(this);
  }

  _render() {
    this.container.insertAdjacentHTML('beforeend',
      `
      <div id="rulesWindow" class="wrapper_rules_container" style="display: none">
        <div class="window">
          <div class="buttonClose">×</div>
          <div class="rules_window_head">
            <div id="rules_title" class="title">
             ${this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.title`)}
            </div>
            <div class="link_box">
            <a id="rules_download_link" style="display: none"> ${this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.download`)}</a>
            </div>
          </div>
          <div id="iframe_rules_box" class="iframe_rules">
            <div class="iframe_rules__spinner_container"></div>
          </div>
       </div>
      </div>`);
  }

  init() {
    this.rulesWrapper = document.getElementById('rulesWindow');
    this.frameBox = document.getElementById('iframe_rules_box');
    const spinnerContainer = this.frameBox.querySelector('.iframe_rules__spinner_container');

    this.buttonClose = this.rulesWrapper.getElementsByClassName('buttonClose')[0];
    this.buttonClose.addEventListener('click', this.close.bind(this));

    this.title = document.getElementById('rules_title');
    this.downloadLink = document.getElementById('rules_download_link');
    this.ControllerSpinner = new ControllerSpinner({ container: spinnerContainer, type: 'game_rules_spinner' });
  }

  update(lang) {
    this.config.lang = lang;
    this.updateTextFields();
  }

  updateTextFields() {
    this.title.innerText = this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.title`);
    this.downloadLink.innerText = this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.download`);
  }

  async show() {
    if (this.isOpened) return;
    this.isOpened = true;

    window.scrollTo(0, 0);
    document.addEventListener('keydown', this._stopSpin, true);

    document.body.classList.add('op_wrapper__disableScroll');
    this.rulesWrapper.style.setProperty('display', 'flex');
    this.rulesWrapper.style.setProperty('pointer-events', 'all');
    this.ControllerSpinner.show();

    try {
      await this._setFrame();
      this.ControllerSpinner.hide();
    } catch (e) {
      console.error(`Can't load rules \nCertification: ${window.OPWrapperService.config.certificationName}\nGame: ${this.config.bundle}\nLanguages: ${this.config.lang}, ${this.fallbackLanguage}`);
    }
  }

  close() {
    if (!this.isOpened) return;
    this.isOpened = false;

    document.removeEventListener('keydown', this._stopSpin, true);
    document.body.classList.remove('op_wrapper__disableScroll');
    this.rulesWrapper.style.setProperty('display', 'none');
    this.rulesWrapper.style.setProperty('pointer-events', 'none');
    this.downloadLink.style.setProperty('display', 'none');
    window.OPWrapperService.initConfig.onButtonClick();

    this._removeFrame();
    this.ControllerSpinner.hide();
  }

  _setFrame() {
    return new Promise(async (resolve, reject) => {
      this.frame = document.createElement('iframe');
      this.frameBox.appendChild(this.frame);
      if (!this.frame) return reject();

      let link = this._getHtmlLink();
      let htmlAvailable = await this._checkIsUrlAvailable(link);
      let pdfAvailable;

      if (!htmlAvailable) {
        link = this.config.lang === SOCIAL_LANGUAGE_KEY ? '' : this._getHtmlLink(this.fallbackLanguage);
        htmlAvailable = await this._checkIsUrlAvailable(link);
      }

      if (!htmlAvailable) {
        let pdfLink = this._getPdfLink();
        pdfAvailable = await this._checkIsUrlAvailable(pdfLink);

        if (!pdfAvailable) {
          pdfLink = this.config.lang === SOCIAL_LANGUAGE_KEY ? '' : this._getPdfLink(this.fallbackLanguage);
          pdfAvailable = await this._checkIsUrlAvailable(pdfLink);
        }

        link = this._getFrameLink(pdfLink);
      }

      if (!htmlAvailable && !pdfAvailable) return reject();

      this.frame.src = link;

      if (pdfAvailable && getUrlParam('pid') !== '787') {
        this.downloadLink.style.setProperty('display', 'block');
        this.downloadLink.setAttribute('href', link + '?download');
      }

      resolve();
    })
  }

  _removeFrame() {
    this.frame.remove();
    this.frame = null;
  }

  _getLocalizedText(key) {
    const text = LocalizationManager.getLocalizedText(key);
    return text ? text : '';
  }

  _getPdfLink(lang) {
    const certification = window.OPWrapperService.config.certificationName;
    return `${this.basePdfUrl}/${this.config.bundle}/${certification}/${lang || this.config.lang}.pdf`;
  }

  _getHtmlLink(lang) {
    const certification = window.OPWrapperService.config.certificationName;
    return `${this.baseHtmlUrl}/${certification}/${this.config.bundle}/${lang || this.config.lang}.html`;
  }

  _getFrameLink(pdfLink) {
    return `${this.baseFrameUrl}?url=${encodeURIComponent(pdfLink)}`;
  }

  async _checkIsUrlAvailable(url) {
    try {
      const response = await fetch(url, {
        method: 'HEAD'
      });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  _stopSpin(e) {
    if (e.keyCode !== 32) return;
    e.stopPropagation();
  }
}
