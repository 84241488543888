import BaseSlotSymbol from "Engine/containers/BaseSlotSymbol";
import { goldFontStyle } from '../../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class Symbol extends BaseSlotSymbol {
    constructor(data, displayItemData) {
        super(data, displayItemData);
        this.animationSymbol = this.getChildByName('animationContainer');
        this.animationFrame = this.getChildByName('frame')
        this.animationFire = this.getChildByName('fire')
        this.incativeTintColor = 0x444444;
    }

    init() {
        super.init();
    }

    changeSymbol(id, reel) {
        super.changeSymbol(id, reel);
        this.image = this.getChildAt(1);
        this.stopAnimation();
        this.stopFire();
        this.stopFrame();
        this.setActive(true);
        if (this.animationSymbol) this.animationSymbol.id = id;
    }

    startAnimation() {
        this.animationSymbol.start(false);
        this.animationFrame.start();
        this.image.visible = false;
    }

    stopAnimation() {
        this.image.visible = true;
        if (this.animationSymbol) this.animationSymbol.stop();
    }

    startFire() {
        this.setActive(true);
        this.animationFire.start();
    }

    stopFire() {
        if (this.animationFire) this.animationFire.stop();
    }

    stopFrame() {
        if (this.animationFrame) this.animationFrame.stop();
    }

    setActive(value) {
            this.image.tint = value ? 16777215 : this.incativeTintColor;
    }
}
