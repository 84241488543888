import EventEmitter from '../eventEmitter';
import { format } from '../../utils/formatCurrency';
import { eInputTypes } from '../../enums/inputTypes';

const inputToEventRelation = {
  [eInputTypes.EIT_BUTTON]: 'click',
  [eInputTypes.EIT_INPUT]: 'change',
}

export default class BaseController extends EventEmitter {
  constructor({ type, overlayHandlers }) {
    super();
    this._type = type;
    this._overlayHandlers = overlayHandlers;
    this._isOverlayToggler = false;
    this._isSpinBlocker = false;
    this._mountPosition = 'afterbegin';
    this._defaultCurrencyDecimal = 2;
    this._eElementsTypes = null;
    this._eButtonsTypes = null;
    this._eInputTypes = null;
    this._eButtonsActions = null;
    this._eInputHandlers = null;
    this._eEventTypes = null;
    this._interactiveElementsIds = null;
    this._localizations = null;
    this._localizationKeysValues = null;
    this._localizationValues = null;
    this._localizationsAsHtml = [];
    this._adaptiveElements = null;
    this._excludedControllerTypes = new Set();
    this._activeActions = new Map();
    this._updateLocalizations = this._updateLocalizations.bind(this);
    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._updateLocalizations);
  }

  init(container) {
    this._container = container;
    container.insertAdjacentHTML(this._mountPosition, this._getMarkup());
    this._mounted = true;

    this._saveInteractiveElements();
    this._setAdaptationListeners();
    this.addActions();
    this._initControllers();
    this._addExtraControllersListeners();
    this._afterInit();
  }

  set(type, value) {
    if (!this._interactiveElements[type]) throw new Error(`Unhandled element type: ${type}`);
    this._interactiveElements[type].textContent = value;
  }

  setInnerHTML(type, value) {
    if (!this._interactiveElements[type]) throw new Error(`Unhandled element type: ${type}`);
    this._interactiveElements[type].innerHTML = String(value);
  }

  addActions() {
    this.removeActions();

    if (this._eButtonsTypes) {
      for (let key in this._eButtonsTypes) {
        const type = this._eButtonsTypes[key];
        const button = this._interactiveElements[type];
        const listener = (e) => {
          if (this._eButtonsActions && this._eButtonsActions.hasOwnProperty(type)) {
            this._eButtonsActions[type](e);
          }
          this.emit(this.getEventName(type, eInputTypes.EIT_BUTTON));
          e.currentTarget.blur(); // prevent button from triggering by space later
        };

        this._registerAction(button, listener, 'click');
      }
    }

    if (this._eInputTypes) {
      for (let key in this._eInputTypes) {
        const type = this._eInputTypes[key];
        const input = this._interactiveElements[type];
        const listener = (e) => {
          if (this._eInputHandlers && this._eInputHandlers.hasOwnProperty(type)) {
            this._eInputHandlers[type](e);
          }

          const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
          this.emit(this.getEventName(type, eInputTypes.EIT_INPUT), value);
        };

        this._registerAction(input, listener, 'input');
      }
    }
  }

  removeActions() {
    const actions = Array.from(this._activeActions.values());
    if (!actions.length) return;
    this._activeActions.clear();

    for (let elementActions of actions) {
      elementActions.forEach(({ unsubscribe }) => unsubscribe());
    }
  }

  removeElementActions(element) {
    if (!element) return;
    const actions = this._activeActions.get(element) || [];
    this._activeActions.delete(element);

    actions.forEach(({ unsubscribe }) => unsubscribe());
  }

  _registerAction(element, action, event) {
    element.addEventListener(event, action, true);

    if (!this._activeActions.has(element)) this._activeActions.set(element, []);
    const currentActions = this._activeActions.get(element);
    this._activeActions.set(element, [...currentActions, {
      action: action,
      unsubscribe: () => {
        element.removeEventListener(event, action, true);
      }
    }])
  }

  getEventName(type, inputType = eInputTypes.EIT_BUTTON) {
    const eventType = inputToEventRelation[inputType];
    if (!eventType) throw new Error(`Unhandled event type: ${eventType}`);
    return `${this.controllerType}__${type}:${eventType}`
  }

  block(types) {
    const buttonTypes = this._getButtonTypesArray(types);
    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.setAttribute('disabled', '');
    });
  }

  unblock(types) {
    const buttonTypes = this._getButtonTypesArray(types);

    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.removeAttribute('disabled');
    });
  }

  isBlocked(type) {
    const button = this._interactiveElements[type];
    return button.getAttribute('disabled') !== null;
  }

  show() {
    if (this._isOverlayToggler && this._overlayHandlers) {
      this._overlayHandlers.show(this.controllerType);
    }
    if (this._isSpinBlocker) window.OPWrapperService.model.blockSpin(this.controllerType);

    this._container.classList.remove('hidden');
    this.onShow();
  }

  hide() {
    if (this._isOverlayToggler && this._overlayHandlers) {
      this._overlayHandlers.hide(this.controllerType);
    }
    if (this._isSpinBlocker) window.OPWrapperService.model.unblockSpin(this.controllerType);

    this._container.classList.add('hidden');
    this.cleanup();
  }

  beforeRemove() {
    this.cleanup();
    window.OPWrapperService.localizations.removeLocalizationChangedCallback(this._updateLocalizations);
    this._mounted = false;
  }

  cleanup() {
    if (this.controllers) Object.values(this.controllers).forEach(controller => controller.cleanup());
  }

  onShow() {
    if (this.controllers) Object.values(this.controllers).forEach(controller => controller.onShow());
  }

  _afterInit() {
  }

  _addExtraControllersListeners() {
  }

  _initControllers() {
    if (!this.controllerTypes) return;

    this._controllers = {};
    for (let key in this.controllerTypes) {
      const type = this.controllerTypes[key];
      if (this._excludedControllerTypes.has(type)) continue;
      const container = this.interactiveElements[type];
      if (!container) throw new Error(`Can't find container for controller type: '${type}'`);

      const controller = this._eControllersClasses[type];
      this._controllers[type] = new controller({ container, type });
      this._addControllerListeners(type);
    }
  }

  _addControllerListeners(type) {
    const controller = this._controllers[type];
    if (!controller.events) return;

    for (let event of Object.values(controller.events[type])) {
      const events = this._getEventsRecursively(event);

      events.forEach(e => {
        this._controllers[type].on(e, (params) => {
          console.log(this.constructor.name, ' proxying event: ', e);
          this.emit(e, params)
        });
      });
    }
  }

  _setAdaptationListeners() {
    if (!this._adaptiveElements) return;
    const resizeObserver = new ResizeObserver((mutations) => {
      mutations.forEach(mutation => {
        // request frame to be sure that element was already rendered;
        // error: ResizeObserver loop completed with undelivered notifications
        requestAnimationFrame(() => this._adaptFontSize(mutation.target));
      });
    });
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') this._adaptFontSize(mutation.target)
      })
    });

    for (const type of this._adaptiveElements) {
      resizeObserver.observe(this.interactiveElements[type]);
      mutationObserver.observe(this.interactiveElements[type], { childList: true, characterData: true });
    }
  }

  _updateLocalizations() {
    if (!this._localizations || !this._mounted) return;
    for (let type in this._localizations) {
      let key = this._localizations[type];
      if (this._localizationKeysValues && this._localizationKeysValues[type]) {
        key = this._substituteLocalizationParams(key, this._localizationKeysValues[type]);
      }

      let text = this._getLocalization(key);
      if (text === key) text = '';
      if (this._localizationValues && this._localizationValues[type]) {
        text = this._substituteLocalizationParams(text, this._localizationValues[type]);
      }

      if (this._localizationsAsHtml.includes(type)) {
        this.setInnerHTML(type, text);
      } else {
        this.set(type, text);
      }
    }
  }

  _substituteLocalizationParams(string, values) {
    let result = string;

    for (let replaceKey in values) {
      result = result.replace(new RegExp(replaceKey.replace('$', '\\$'), 'gi'), values[replaceKey]);
    }

    return result;
  }

  _getLocalization(key) {
    return window.OPWrapperService.localizations.getLocalizedText(key);
  }

  _getButtonTypesArray(types) {
    if (!Array.isArray(types)) {
      types = types ? [types] : [];
    }

    if (!types.length) {
      types = Object.values(this._eButtonsTypes);
    }

    return types;
  }

  _getMarkup() {
    throw new Error(`You must override 'getMarkup' method inside derived class`);
  }

  get elementsIdPrefix() {
    throw new Error(`You must override 'get elementsIdPrefix' method inside derived class`);
  }

  get elementsIdSuffix() {
    return '';
  }

  _saveInteractiveElements() {
    this._interactiveElements = {};
    for (let type in this.interactiveElementsIds) {
      const id = this.interactiveElementsIds[type];
      const element = document.getElementById(id);

      if (!element) throw new Error(`Can't find element by given id: '${id}'`);
      this._interactiveElements[type] = element;
    }
  }

  _adaptFontSize(el) {
    if (getComputedStyle(el).fontSize === '' || (el._savedScrollWidth === el.scrollWidth && el._savedTextContent === el.textContent)) return; //it is a safeguard against an endless loop
    // css overflow property must be specified on an element;
    if (!this._prefferedFontSizes) {
      this._prefferedFontSizes = new Map();
    }
    if (!this._prefferedFontSizes.has(el)) {
      const fontSize = this._getElementComputedFontSize(el); //rem
      this._prefferedFontSizes.set(el, fontSize);
    }

    let iterationCount = 0;
    while (el.offsetWidth === el.scrollWidth) {
      if (iterationCount >= 100) break; //it is a safeguard against an endless loop
      iterationCount++
      const fontSizeOverridden = el.style.fontSize ? parseFloat(el.style.fontSize) : 0; // rem
      const fontSizeComputed = this._getElementComputedFontSize(el); //rem
      const fontSize = fontSizeOverridden || fontSizeComputed; //getComputedStyle give incorrect fontSize for a Google Pixel device
      if (fontSize >= this._prefferedFontSizes.get(el)) break;
      el.style.fontSize = (fontSize + 0.05) + 'rem';
    }
    iterationCount = 0;
    while (el.offsetWidth < el.scrollWidth) {
      if (iterationCount >= 100) break; // it is a safeguard against an endless loop
      iterationCount++
      const fontSizeOverridden = el.style.fontSize ? parseFloat(el.style.fontSize) : 0; // rem
      const fontSizeComputed = this._getElementComputedFontSize(el); //rem
      const fontSize = fontSizeOverridden || fontSizeComputed; //getComputedStyle give incorrect fontSize for a Google Pixel device
      el.style.fontSize = (fontSize - 0.05) + 'rem';
    }
    el._savedScrollWidth = el.scrollWidth;
    el._savedTextContent = el.textContent;
  }

  _moneyFormat(value) {
    return `${format(value, window.OPWrapperService.currencyInfo.decimals)} ${window.OPWrapperService.currencyInfo.currency.toUpperCase()}`
  }

  _getEventsRecursively(event, events = []) {
    if (typeof event === 'object') {
      const keys = Object.keys(event);
      for (let key of keys) {
        this._getEventsRecursively(event[key], events);
      }
    } else if (typeof event === 'string') {
      events.push(event);
    }

    return events;
  }

  _getElementComputedFontSize(el) {
    return parseFloat(getComputedStyle(el).fontSize) * window.OPWrapperService.ScaleManager.currentScaleCoefficient / window.OPWrapperService.ScaleManager.defaultFontSize; // rem
  }

  get interactiveElementsIds() {
    if (!this._interactiveElementsIds) {
      this._interactiveElementsIds = {};
      for (let key in this.elementsTypes) {
        const type = this.elementsTypes[key];
        const idSuffix = this.elementsIdSuffix ? `_${this.elementsIdSuffix}` : '';
        this._interactiveElementsIds[type] = `${this.elementsIdPrefix}__${this.controllerType}${idSuffix}__${type}`;
      }
    }
    return this._interactiveElementsIds;
  }

  get elementsTypes() {
    return this._eElementsTypes;
  }

  get interactiveElements() {
    return this._interactiveElements;
  }

  get buttonsTypes() {
    return this._eButtonsTypes;
  }

  get controllers() {
    return this._controllers;
  }

  get controllerTypes() {
  }


  get events() {
    if (!this._eEventTypes) {
      this._eEventTypes = {};
    }

    if (this.controllers) {
      for (let controller of Object.values(this.controllers)) {
        Object.assign(this._eEventTypes, controller.events);
      }
    }

    return { [this.controllerType]: this._eEventTypes }
  }

  get controllerType() {
    if (!this._type) {
      throw new Error(`You must specify controller type for '${this.constructor.name}'`);
    }
    return this._type;
  }

  get isHidden() {
    return this._container.classList.contains('hidden');
  }

  set scaleData(data) {

  }
}
