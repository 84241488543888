export default {
  ESI_WILD: 1,
  ESI_WATERMELON: 2,
  ESI_CHERRY: 3,
  ESI_GRAPE: 4,
  ESI_LEMON: 5,
  ESI_ORANGE: 6,
  ESI_PLUM: 7,
  ESI_SCATTER: 8,
}
