import GameModel from '../model/GameModel';
import DataProvider from './XXXData';
import XXXDataParser from './XXXDataParser';

export default new class ServerManager {
  constructor() {
    this.dataProvider = null;
    this.timeout = null;
    this.dataProvider = new DataProvider();
  }

  async onInit() {
    let request = {
      action: 'init'
    };
    const data = await this.dataProvider.sendInit(request);
    GameModel.setNewData(XXXDataParser.parseInit(data));
  }

  async onStartSpin() {
    let request = {
      betIndex: GameModel.betIndex,
      action: 'spin'
    };
    const data = await this.dataProvider.sendMessage(request, 'spin');
    GameModel.setNewData(XXXDataParser.parseSpin(data));
    return data;
  }

  async onBuyBonus() {
    let request = {
      betIndex: GameModel.betIndex,
      type: GameModel.bonusPurchaseData.type,
      action: 'buy'
    };
    const data = await this.dataProvider.sendMessage(request, 'buy');
    GameModel.setNewData(XXXDataParser.parsePurchaseBonusGame(data));
    return data;
  }

  onResponse(data) {
    GameModel.setNewData(data.params);
  }
}
